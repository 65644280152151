export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "COMM_GREETING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님 안녕하세요!"])},
        "COMM_ACTIVE_MEMBER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동할 멤버 유형"])},
        "COMM_BTN_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청"])},
        "COMM_BTN_NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음에 하기"])},
        "COMM_SELECT_MEMBERTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버 유형을 선택해주세요!"])},
        "COMM_NEED_MEMBER_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버 신청 필요"])},
        "COMM_OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인"])},
        "COMM_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거절"])}
      },
      "en": {
        "COMM_GREETING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HI!"])},
        "COMM_ACTIVE_MEMBER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member type"])},
        "COMM_BTN_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "COMM_BTN_NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next time"])},
        "COMM_SELECT_MEMBERTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select member type!"])},
        "COMM_NEED_MEMBER_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to apply to be a member"])},
        "COMM_OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "COMM_NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
      }
    }
  })
}
