export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "LIST_TITLE_CRE_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 생성"])},
        "CHAN_LIST_MSG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명인증을 하면 채널을 생성할 수 있어요"])},
        "CHAN_LIST_NAME_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
        "CHAN_LIST_NAME_SORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정렬"])}
      },
      "en": {
        "LIST_TITLE_CRE_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Channel"])},
        "CHAN_LIST_MSG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a channel if you authenticate your real name"])},
        "CHAN_LIST_NAME_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "CHAN_LIST_NAME_SORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])}
      }
    }
  })
}
