export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOOK_MSG_NOBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록이 없어요."])},
        "EDIT_BOOK_MSG_CREBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼을 눌러 주소록을 생성해보세요."])},
        "EDIT_BOOK_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록이 삭제되었습니다."])},
        "EDIT_BOOK_MSG_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 삭제에 실패했습니다."])},
        "EDIT_BOOK_TITLE_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 수정"])}
      },
      "en": {
        "EDIT_BOOK_MSG_NOBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no address book."])},
        "EDIT_BOOK_MSG_CREBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the button to create an address book."])},
        "EDIT_BOOK_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address book has been deleted."])},
        "EDIT_BOOK_MSG_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete address book."])},
        "EDIT_BOOK_TITLE_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address Books"])}
      }
    }
  })
}
