export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOOK_MSG_NOBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 대상이 없습니다."])},
        "COMM_MSG_DEL_BOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록을 삭제하시겠습니까?"])},
        "COMM_MSG_DEL_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 유저를 주소록에서 제외하시겠습니까?"])}
      },
      "en": {
        "EDIT_BOOK_MSG_NOBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no target."])},
        "COMM_MSG_DEL_BOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your address book?"])},
        "COMM_MSG_DEL_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to exclude this user from the address book?"])}
      }
    }
  })
}
