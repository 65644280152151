export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SEAR_MSG_LOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무엇을 찾고 계신가요?"])},
        "SEAR_MSG_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색키워드를 입력해주세요"])},
        "SEAR_TITLE_FIND_CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["산업군별 채널 찾기"])},
        "SEAR_TITLE_FIND_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분류별 채널 찾기"])},
        "SEAR_MSG_CHAN_RECOMMEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널을 추천해드릴게요!"])},
        "SEAR_TAB_RECE_CHANNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신"])},
        "SEAR_TAB_ACT_CAHNNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근활동"])},
        "SEAR_MSG_RECE_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 검색어"])},
        "SEAR_MSG_NOT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 검색한 결과가 없어요"])},
        "SEAR_BTN_CLEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체삭제"])},
        "SEAR_TAB_POP_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기"])}
      },
      "en": {
        "SEAR_MSG_LOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you looking for?"])},
        "SEAR_MSG_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search…"])},
        "SEAR_TITLE_FIND_CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels by Categories"])},
        "SEAR_TITLE_FIND_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels by Labels"])},
        "SEAR_MSG_CHAN_RECOMMEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended channels for you"])},
        "SEAR_TAB_RECE_CHANNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
        "SEAR_TAB_ACT_CAHNNEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Activity"])},
        "SEAR_MSG_RECE_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Keywords"])},
        "SEAR_MSG_NOT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! You haven't searched anything yet."])},
        "SEAR_BTN_CLEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
        "SEAR_TAB_POP_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])}
      }
    }
  })
}
