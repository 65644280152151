export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SAMP_NAME_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예시글 설정"])},
        "SAMP_BTN_IMPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 가져오기"])},
        "SAMP_TITLE_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성하기"])}
      },
      "en": {
        "SAMP_NAME_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a Sample"])},
        "SAMP_BTN_IMPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a Sample"])},
        "SAMP_TITLE_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a Sample"])}
      }
    }
  })
}
