export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MEM_DETAIL_TITLE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록"])},
        "MEM_DETAIL_TITLE_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버유형 상세"])}
      },
      "en": {
        "MEM_DETAIL_TITLE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
        "MEM_DETAIL_TITLE_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Type Details"])}
      }
    }
  })
}
