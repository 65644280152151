export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "COMM_QUESTION_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독을 안하면 알림을 받지 못하나요?"])},
        "COMM_QUESTION_TWO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버와 구독자의 차이점은 무엇인가요?"])},
        "COMM_QUESTION_THREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보를 수정하고 싶어요."])},
        "COMM_QUESTION_FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보는 안전하게 보호되나요?"])},
        "COMM_ANSWER_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네 맞아요. 구독한 채널의 알림만 받으실 수 있어요!"])},
        "COMM_ANSWER_TWO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우선 구독자가 되면 채널에서 다양한 형태의 소통을 할 수 있게되는데요.<br>채널의 일원으로서 더 많은 권한을 얻고 활동을 하기 원하면 멤버신청을 통해 멤버가 될 수 있답니다."])},
        "COMM_ANSWER_THREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하이브릭 하단 메뉴 우측 내정보 > 프로필 카드를 클릭하면 나오는 설정페이지에서 자유롭게 정보를 수정해봐요!"])},
        "COMM_ANSWER_FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하이브릭은 소셜로그인을 통해 들어오게 되기 때문에 개인 정보는 하이브릭이 아닌 해당 소셜의 정책에 따라 보호받게 됩니다."])},
        "COMM_BTN_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로가기"])}
      },
      "en": {
        "COMM_QUESTION_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If I don't subscribe, will I not receive notifications?"])},
        "COMM_QUESTION_TWO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the difference between a member and a subscriber?"])},
        "COMM_QUESTION_THREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to edit my personal information."])},
        "COMM_QUESTION_FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is my personal information safely protected?"])},
        "COMM_ANSWER_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes that's right. You will only receive notifications from channels you have subscribed to!"])},
        "COMM_ANSWER_TWO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First of all, if you become a subscriber, you will be able to communicate in various forms on the channel.<br>If you want to gain more rights and participate in activities as a member of the channel, you can become a member by applying for membership."])},
        "COMM_ANSWER_THREE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to edit your information on the settings page that appears when you click My Information > Profile Card on the right side of the menu at the bottom of More Notifications!"])},
        "COMM_ANSWER_FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because The Notification is accessed through social login, personal information is protected according to the social policy, not The Notification's policy."])},
        "COMM_BTN_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])}
      }
    }
  })
}
