export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "REQ_MEM_NAME_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버신청"])},
        "REQ_MEM_TITLE_CONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청내용"])},
        "REQ_MEM_BTN_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청"])},
        "REQ_MEM_MSG_SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청되었습니다!"])},
        "REQ_MEM_MSG_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 내용을 입력해주세요."])}
      },
      "en": {
        "REQ_MEM_NAME_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Member Access"])},
        "REQ_MEM_TITLE_CONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Content"])},
        "REQ_MEM_BTN_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "REQ_MEM_MSG_SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied!"])},
        "REQ_MEM_MSG_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the application details."])}
      }
    }
  })
}
