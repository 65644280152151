export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "FOLLOW_NAME_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소유자"])},
        "FOLLOW_NAME_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버"])},
        "FOLLOW_NAME_FOLLOWER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자"])},
        "FOLLOW_MSG_NOEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 정보 없음"])},
        "FOLLOW_MSG_NONUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 정보 없음"])},
        "FOLLOW_BTN_SENDNOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 보내기"])},
        "FOLLOW_NAME_MANAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자"])},
        "FOLLOW_MSG_NOAUTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자는 세부정보 확인이 불가합니다."])}
      },
      "en": {
        "FOLLOW_NAME_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
        "FOLLOW_NAME_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
        "FOLLOW_NAME_FOLLOWER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follower"])},
        "FOLLOW_MSG_NOEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Email Info"])},
        "FOLLOW_MSG_NONUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Phone Number Info"])},
        "FOLLOW_BTN_SENDNOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Noti"])},
        "FOLLOW_NAME_MANAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
        "FOLLOW_MSG_NOAUTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber cannot check details."])}
      }
    }
  })
}
