export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MANA_NAME_CAHN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 관리"])},
        "MANA_MSG_CAHN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널정보를 수정할 수 있어요."])},
        "MANA_NAME_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자 관리"])},
        "MANA_MSG_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자에게 권한을 부여할 수 있어요."])},
        "MANA_NAME_ADDRBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 관리"])},
        "MANA_MSG_ADDRBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록을 생성, 수정할 수 있어요."])},
        "MANA_NAME_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 관리"])},
        "MANA_MSG_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판을 생성, 수정할 수 있어요."])},
        "MANA_MSG_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["죄송합니다. 잠시 후 다시 시도해주세요."])},
        "MANA_TITLE_CHANDETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 상세"])},
        "MANA_TITLE_EDITCHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 수정"])}
      },
      "en": {
        "MANA_NAME_CAHN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Channel"])},
        "MANA_MSG_CAHN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify channel information."])},
        "MANA_NAME_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Followers"])},
        "MANA_MSG_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant permissions to followers."])},
        "MANA_NAME_ADDRBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Address Book"])},
        "MANA_MSG_ADDRBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and Edit address book."])},
        "MANA_NAME_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Board"])},
        "MANA_MSG_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and Edit Board."])},
        "MANA_MSG_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. Please try again in a moment."])},
        "MANA_TITLE_CHANDETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Detail"])},
        "MANA_TITLE_EDITCHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Channel"])}
      }
    }
  })
}
