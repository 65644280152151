export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "FOLLOW_MSG_USERINFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름 또는 전화번호를 입력해주세요"])},
        "FOLLOW_BTN_ORDER_CRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록순"])},
        "FOLLOW_BTN_ORDER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름순"])},
        "FOLLOW_BTN_MANA_MEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버관리"])},
        "FOLLOW_BTN_MANA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형관리"])},
        "FOLLOW_MSG_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인되었습니다!"])},
        "FOLLOW_TITLE_MANA_MEMTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버유형관리"])},
        "FOLLOW_MSG_WAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상대방이 구독하기를 기다리는 중 입니다."])},
        "FOLLOW_MSG_AUTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 시 자동으로 매니저로 등록됩니다."])},
        "FOLLOW_TITLE_ADD_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저 추가"])}
      },
      "en": {
        "FOLLOW_MSG_USERINFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write down name or phone number"])},
        "FOLLOW_BTN_ORDER_CRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "FOLLOW_BTN_ORDER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "FOLLOW_BTN_MANA_MEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Members"])},
        "FOLLOW_BTN_MANA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Types"])},
        "FOLLOW_MSG_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved!"])},
        "FOLLOW_TITLE_MANA_MEMTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Member Types"])},
        "FOLLOW_MSG_WAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the other person to subscribe."])},
        "FOLLOW_MSG_AUTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are automatically registered as managers upon subscription."])},
        "FOLLOW_TITLE_ADD_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a User"])}
      }
    }
  })
}
