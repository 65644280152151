export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOOK_TITLE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부필터"])},
        "EDIT_BOOK_MSG_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력해주세요"])},
        "EDIT_BOOK_MSG_BOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록명을 입력해주세요"])},
        "EDIT_BOOK_BTN_CRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록순"])},
        "EDIT_BOOK_BTN_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름순"])},
        "EDIT_BOOK_BTN_EXCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀<br>업로드"])},
        "EDIT_BOOK_BTN_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나를<br>추가"])},
        "EDIT_BOOK_BTN_DIRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접<br>추가"])},
        "EDIT_BOOK_BTN_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저<br>선택"])},
        "EDIT_BOOK_BTN_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 생성"])},
        "EDIT_BOOK_NAME_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["학번"])},
        "EDIT_BOOK_NAME_MAJOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["학과"])},
        "EDIT_BOOK_NAME_DUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직책"])},
        "EDIT_BOOK_NAME_JOB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직무"])},
        "EDIT_BOOK_NAME_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직급"])},
        "EDIT_BOOK_NAME_ADDUSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저 추가"])},
        "EDIT_BOOK_MSG_EXCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀업로드 기능은<br>하이브릭 PC버전에서만 가능합니다."])}
      },
      "en": {
        "EDIT_BOOK_TITLE_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Filters"])},
        "EDIT_BOOK_MSG_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write down a name"])},
        "EDIT_BOOK_MSG_BOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write down a name of the Address Book"])},
        "EDIT_BOOK_BTN_CRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "EDIT_BOOK_BTN_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "EDIT_BOOK_BTN_EXCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload<br>Excel File"])},
        "EDIT_BOOK_BTN_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add<br>Me"])},
        "EDIT_BOOK_BTN_DIRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add<br>Manually"])},
        "EDIT_BOOK_BTN_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select<br>User"])},
        "EDIT_BOOK_BTN_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Address Book"])},
        "EDIT_BOOK_NAME_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID #"])},
        "EDIT_BOOK_NAME_MAJOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
        "EDIT_BOOK_NAME_DUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duty"])},
        "EDIT_BOOK_NAME_JOB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
        "EDIT_BOOK_NAME_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "EDIT_BOOK_NAME_ADDUSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
        "EDIT_BOOK_MSG_EXCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Excel upload function is<br>only available in the PC version."])}
      }
    }
  })
}
