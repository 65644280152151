export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "BOTTOM_MSG_WHERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무엇을 작성하실건가요?"])},
        "BOTTOM_MSG_NOPERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 없습니다."])},
        "BOTTOM_MSG_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널을 선택해주세요"])},
        "BOMMOM_MSG_LETTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어디로 보내실건가요?"])},
        "BOTTOM_BTN_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성하기"])},
        "BOTTOM_MSG_NOCHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨텐츠를 작성할 수 있는 채널이 없어요"])},
        "BOTTOM_MSG_NOFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 채널의 멤버가 아닙니다. 멤버로 신청 후 이용해주세요."])},
        "BOTTOM_MSG_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널을 다시 선택 후 확인 버튼을 눌러주세요!"])}
      },
      "en": {
        "BOTTOM_MSG_WHERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you going to write?"])},
        "BOTTOM_MSG_NOPERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission."])},
        "BOTTOM_MSG_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a channel"])},
        "BOMMOM_MSG_LETTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you going to write contents?"])},
        "BOTTOM_BTN_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write"])},
        "BOTTOM_MSG_NOCHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's no channel to create content."])},
        "BOTTOM_MSG_NOFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not a follower of that channel. Please apply for followers."])},
        "BOTTOM_MSG_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the channel again and press the OK button!"])}
      }
    }
  })
}
