export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "BOAR_SELECT_MSG_COPY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글을 복사할 게시판을 선택해주세요"])},
        "BOAR_SELECT_MSG_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글을 이동할 게시판을 선택해주세요"])},
        "BOAR_SELECT_MSG_NOBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판을 선택해주세요."])},
        "BOAR_SELECT_BTN_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동"])},
        "BOAR_SELECT_BTN_COPY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사"])}
      },
      "en": {
        "BOAR_SELECT_MSG_COPY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the board to which you want to copy the post"])},
        "BOAR_SELECT_MSG_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the board to move the post to"])},
        "BOAR_SELECT_MSG_NOBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a board."])},
        "BOAR_SELECT_BTN_MOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
        "BOAR_SELECT_BTN_COPY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])}
      }
    }
  })
}
