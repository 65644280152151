export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "ASK_TITLE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
        "ASK_TITLE_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용"])},
        "ASK_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
        "ASK_MSG_BEFORE_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의글을 저장하시겠습니까?"])},
        "ASK_MSG_AFTER_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되었습니다."])},
        "ASK_MSG_NOTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을 입력해주세요."])},
        "ASK_MSG_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글의 내용을 입력해주세요."])},
        "ASK_MSG_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상적으로 접수되었습니다."])},
        "ASK_MSG_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예기치 못한 이유로 접수되지 못했습니다."])}
      },
      "en": {
        "ASK_TITLE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "ASK_TITLE_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])},
        "ASK_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "ASK_MSG_BEFORE_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save your inquiry?"])},
        "ASK_MSG_AFTER_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
        "ASK_MSG_NOTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a title."])},
        "ASK_MSG_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a contents."])},
        "ASK_MSG_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your inquiry has been successfully received."])},
        "ASK_MSG_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to receive due to unexpected reason."])}
      }
    }
  })
}
