export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "CHAN_LIST_NAME_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
        "CHAN_LIST_NAME_SORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정렬"])},
        "CHAN_BOTTOM_TITLE_CATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["산업군을 선택해주세요."])},
        "CHAN_BOTTOM_BTN_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택완료"])},
        "CHAN_BOTTOM_TAB_POPULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기"])},
        "CHAN_BOTTOM_TAB_RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근활동순"])},
        "CHAN_BOTTOM_TITLE_SORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어떻게 정렬할까요?"])},
        "CHAN_BOTTOM_TITLE_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형을 선택해주세요."])},
        "CHAN_BOTTOM_TAB_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자"])},
        "CHAN_BOTTOM_TAB_FOUNDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개설자"])}
      },
      "en": {
        "CHAN_LIST_NAME_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "CHAN_LIST_NAME_SORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
        "CHAN_BOTTOM_TITLE_CATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a category."])},
        "CHAN_BOTTOM_BTN_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "CHAN_BOTTOM_TAB_POPULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
        "CHAN_BOTTOM_TAB_RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
        "CHAN_BOTTOM_TITLE_SORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How should we sort it?"])},
        "CHAN_BOTTOM_TITLE_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a type."])},
        "CHAN_BOTTOM_TAB_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
        "CHAN_BOTTOM_TAB_FOUNDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder"])}
      }
    }
  })
}
