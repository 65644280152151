export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SEAR_TITLE_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 검색"])},
        "SEAR_MSG_RECE_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 검색어"])},
        "SEAR_BTN_CLEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체삭제"])},
        "SEAR_MSG_ENTER_CHANNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널명을 입력해주세요"])},
        "SEAR_MSG_NOT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 검색한 결과가 없어요"])}
      },
      "en": {
        "SEAR_TITLE_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Channels"])},
        "SEAR_MSG_RECE_KEYWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Keywords"])},
        "SEAR_BTN_CLEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
        "SEAR_MSG_ENTER_CHANNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a channel's name."])},
        "SEAR_MSG_NOT_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! You haven't searched anything yet."])}
      }
    }
  })
}
