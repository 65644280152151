<template>
    <div class="pagePaddingWrap" style="width: 100%; height: 100%;">
        <p class="font16 textLeft">{{ this.$t('COMM_MSG_NOPERM') }}</p>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

</style>
