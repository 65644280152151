export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SEAR_TITLE_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쪽지 검색"])},
        "SEAR_MSG_ENTER_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을 입력해주세요"])},
        "SEAR_MSG_ENTER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성자명을 입력해주세요"])},
        "SEAR_MSG_SELE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜를 선택해주세요"])},
        "SEAR_MSG_SELE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 선택"])},
        "SEAR_MSG_SELE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업무 상태를 선택해주세요"])},
        "SEAR_BTN_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색하기"])},
        "SEAR_MSG_ENTER_CHANNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널명을 입력해주세요"])},
        "SEAR_TITLE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 검색"])},
        "SEAR_MSG_ENTER_FILENAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일명을 입력해주세요"])},
        "SEAR_TITLE_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글 검색"])}
      },
      "en": {
        "SEAR_TITLE_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Contents"])},
        "SEAR_MSG_ENTER_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a title."])},
        "SEAR_MSG_ENTER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a creator’s name."])},
        "SEAR_MSG_SELE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
        "SEAR_MSG_SELE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Label"])},
        "SEAR_MSG_SELE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Work Status"])},
        "SEAR_BTN_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "SEAR_MSG_ENTER_CHANNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a channel's name."])},
        "SEAR_TITLE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Files"])},
        "SEAR_MSG_ENTER_FILENAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a file's name."])},
        "SEAR_TITLE_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Posts"])}
      }
    }
  })
}
