export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOARD_NAME_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 수정"])},
        "EDIT_BOARD_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 이름"])},
        "EDIT_BOARD_MSG_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 이름을 입력하세요."])},
        "EDIT_BOARD_DROP_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판의 유형을 선택해주세요."])},
        "EDIT_BOARD_TITLE_FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 기능"])},
        "EDIT_BOARD_BTN_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
        "EDIT_BOARD_BTN_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일첨부"])},
        "EDIT_BOARD_BTN_ANONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["익명"])},
        "EDIT_BOARD_BTN_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명"])},
        "EDIT_BOARD_BTN_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태설정"])},
        "EDIT_BOARD_BTN_HIDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 비공개(미권한자)"])},
        "EDIT_BOARD_TITLE_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예시글 작성"])},
        "EDIT_BOARD_MSG_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예시글을 추가해주세요"])},
        "EDIT_BOARD_MSG_ISSAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예시글 있음"])},
        "EDIT_BOARD_BTN_ALLMEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자전체"])},
        "EDIT_BOARD_TITLE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글 열람"])},
        "EDIT_BOARD_BTN_NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안함"])},
        "EDIT_BOARD_MSG_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한을 설정해주세요."])},
        "EDIT_BOARD_TITLE_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 작성"])},
        "EDIT_BOARD_MSG_STATUSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태O"])},
        "EDIT_BOARD_MSG_STATUSX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태X"])},
        "EDIT_BOARD_MSG_COMMENTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/댓글O"])},
        "EDIT_BOARD_MSG_COMMENTX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/댓글X"])},
        "EDIT_BOARD_MSG_FILEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/파일O"])},
        "EDIT_BOARD_MSG_FILEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/파일X"])},
        "EDIT_BOARD_MSG_ANONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/익명"])},
        "EDIT_BOARD_MSG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명"])},
        "EDIT_BOARD_MSG_HIDETITLEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 비공개(미권한자)"])},
        "EDIT_BOARD_MSG_HIDETITLEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 공개(미권한자)"])},
        "EDIT_BOARD_MSG_SHARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판을 공유할 대상을 선택해주세요."])},
        "EDIT_BOARD_TITLE_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글 작성"])},
        "EDIT_BOARD_WRITE_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성권한"])},
        "EDIT_BOARD_READ_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["열람권한"])},
        "EDIT_BOARD_COMMENT_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글권한"])},
        "EDIT_BOARD_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정하기"])},
        "EDIT_BOARD_MSG_EDITSUCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적으로 수정되었습니다."])},
        "EDIT_BOARD_MSG_SELECTMEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["먼저 공유대상을 선택해주세요."])}
      },
      "en": {
        "EDIT_BOARD_NAME_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Board"])},
        "EDIT_BOARD_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board Name"])},
        "EDIT_BOARD_MSG_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a name of the board"])},
        "EDIT_BOARD_DROP_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of the board"])},
        "EDIT_BOARD_TITLE_FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board Functions"])},
        "EDIT_BOARD_BTN_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "EDIT_BOARD_BTN_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach File"])},
        "EDIT_BOARD_BTN_ANONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous"])},
        "EDIT_BOARD_BTN_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
        "EDIT_BOARD_BTN_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Status"])},
        "EDIT_BOARD_BTN_HIDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Title to Non-Member"])},
        "EDIT_BOARD_TITLE_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Sample Text"])},
        "EDIT_BOARD_MSG_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add default sample text"])},
        "EDIT_BOARD_MSG_ISSAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Sample Text 0"])},
        "EDIT_BOARD_BTN_ALLMEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All members"])},
        "EDIT_BOARD_TITLE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Contents"])},
        "EDIT_BOARD_BTN_NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "EDIT_BOARD_MSG_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set an access status"])},
        "EDIT_BOARD_TITLE_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "EDIT_BOARD_MSG_STATUSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Status O"])},
        "EDIT_BOARD_MSG_STATUSX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Status X"])},
        "EDIT_BOARD_MSG_COMMENTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Comments O"])},
        "EDIT_BOARD_MSG_COMMENTX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Comments X"])},
        "EDIT_BOARD_MSG_FILEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/File O"])},
        "EDIT_BOARD_MSG_FILEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/File X"])},
        "EDIT_BOARD_MSG_ANONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Anonymous"])},
        "EDIT_BOARD_MSG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Identified"])},
        "EDIT_BOARD_MSG_HIDETITLEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/HIde Title to non-member"])},
        "EDIT_BOARD_MSG_HIDETITLEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Show Title to non-member"])},
        "EDIT_BOARD_MSG_SHARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a target to share the board with."])},
        "EDIT_BOARD_TITLE_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Contents"])},
        "EDIT_BOARD_WRITE_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Permission"])},
        "EDIT_BOARD_READ_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Permission"])},
        "EDIT_BOARD_COMMENT_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment Permission"])},
        "EDIT_BOARD_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "EDIT_BOARD_MSG_EDITSUCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited successfully."])},
        "EDIT_BOARD_MSG_SELECTMEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First, please select a target to share."])}
      }
    }
  })
}
