export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "CHAN_POST_NONE_CONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼을 눌러 첫 컨텐츠를 작성해보세요."])},
        "CHAN_POST_MSG_SET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성 setting 중 오류"])},
        "CHAN_POST_NO_PERMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 없습니다."])},
        "CHAN_POST_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
        "CHAN_POST_CRE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성자"])},
        "CHAN_POST_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸"])},
        "CHAN_POST_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
        "CHAN_POST_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
        "CHAN_POST_STAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분류"])},
        "CHAN_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널명"])},
        "CHAN_CREDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든일"])},
        "CHAN_BOARD_FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 기능"])},
        "BOARD_NO_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨텐츠가 없어요!"])},
        "BOARD_NEW_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼을 눌러 첫 게시글을 작성해보세요."])},
        "COMM_TITLE_GUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손님"])},
        "COMM_DO_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명인증을 하면 익명게시판에 글을 작성할 수 있어요"])}
      },
      "en": {
        "CHAN_POST_NONE_CONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first content for your channel's bulletin board."])},
        "CHAN_POST_MSG_SET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during creation setting"])},
        "CHAN_POST_NO_PERMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission."])},
        "CHAN_POST_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "CHAN_POST_CRE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writer"])},
        "CHAN_POST_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
        "CHAN_POST_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "CHAN_POST_FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "CHAN_POST_STAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "CHAN_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Name"])},
        "CHAN_CREDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
        "CHAN_BOARD_FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board"])},
        "BOARD_NO_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no written content."])},
        "BOARD_NEW_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the button to create your first post."])},
        "COMM_TITLE_GUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
        "COMM_DO_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you verify your real name, you can write on the anonymous board."])}
      }
    }
  })
}
