export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOOK_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 이름"])},
        "EDIT_BOOK_BTN_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성하기"])},
        "EDIT_BOOK_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정하기"])},
        "EDIT_BOOK_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 이름을 입력해주세요."])},
        "EDIT_BOOK_MSG_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 중 문제가 발생했습니다. 다시 시도해주세요."])}
      },
      "en": {
        "EDIT_BOOK_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Name"])},
        "EDIT_BOOK_BTN_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "EDIT_BOOK_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "EDIT_BOOK_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a name for address book."])},
        "EDIT_BOOK_MSG_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem saving. Please try again."])}
      }
    }
  })
}
