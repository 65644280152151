<template>
  <!-- <div :style="calcWhiteHeight" style="height: var(--whiteHeightLength); background-color: white; border-radius: 10px; margin: 10px;padding: 15px;"> -->
  <div class="memberPopText">
    <p id="helpMemberText" class="commonBlack textLeft font16">
      <b>'멤버'</b>는 당신의 채널에 소속이 된 애정 구독자입니다! <br><br>
      <b>구독자</b>는 언제든지 <b>멤버를 신청</b>할 수 있고, <br>
      <b>관리자</b>는 <b>멤버 리스트</b>를 통해, <br><br>
      1) 한눈에 <b>멤버를 조회</b>하고, 손쉽게 <b>주소록이나 매니저를 관리</b>할 수 있어요!<br>
      2) 멤버를 한번에 여러 <b>주소록에 등록</b>하거나, 그 멤버에게만 <b>개별 알림</b>을 보낼 수 있어요.<br><br>
      <!-- 하이브릭의 채널 멤버 관리를 활용하여 효과적으로 채널을 관리해보세요! <br><br> -->
    </p>
    <img src="../../../assets/images/main/message_logo.png" class="memberPopAlimImg" />
  </div>

</template>
<script>

</script>
<style>
.memberPopText {top: 20%; background-color:white;margin:10px;border-radius:10px;padding:15px;min-height: 300px;max-height: 60%;position: absolute;z-index: 999;}
.memberPopAlimImg {width: 100px;right: 20px; float: right; position: absolute; z-index: 999;}
</style>
