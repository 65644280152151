
<i18n>
{
  "ko": {
    "CHAN_TITLE_CREDATE": "개설일",
    "CHAN_TITLE_FOLLOW": "구독자",
    "CHAN_TITLE_CONTNUM": "누적 쪽지",
    "CHAN_BTN_UNFOLLOW": "구독취소",
    "CHAN_BTN_MEMREQ": "멤버신청",
    "CHAN_BTN_FOLLOW": "구독하기",
    "CHAN_NAME_GUEST": "손님",
    "CHAN_BTN_EDIT": "편집",
    "CHAN_MSG_AGAIN_MEMBER": "회원님은 현재 구독자상태이며,<br>언제든지 다시 멤버신청을 할 수 있습니다!",
    "EDIT_NAME_CHAN": "채널 수정",
    "CHAN_MSG_NOCANCEL": "관리자는 구독취소가 불가능합니다.<br>소유자에게 문의해주세요.",
    "CHAN_MSG_UNFOLLOW": "구독 취소가 완료되었습니다.",
    "CHAN_MSG_ASK_UNFOLLOW": "구독을 취소하시겠습니까?",
    "CHAN_MSG_OFF_NOTI": "채널 알림이 비활성화 되었습니다.",
    "CHAN_MSG_ON_NOTI": "채널 알림이 활성화 되었습니다."
  },
  "en": {
    "CHAN_TITLE_CREDATE": "Created",
    "CHAN_TITLE_FOLLOW": "Followers",
    "CHAN_TITLE_CONTNUM": "Total Contents",
    "CHAN_BTN_UNFOLLOW": "Unfollow",
    "CHAN_BTN_MEMREQ": "Member Request",
    "CHAN_BTN_FOLLOW": "Follow",
    "CHAN_NAME_GUEST": "Guest",
    "CHAN_BTN_EDIT": "Edit",
    "CHAN_MSG_AGAIN_MEMBER": "You are currently a follower,<br>and you can apply again at any time!",
    "EDIT_NAME_CHAN": "Edit a Channel",
    "CHAN_MSG_NOCANCEL": "Manager cannot unfollow<br>Contact the owner.",
    "CHAN_MSG_UNFOLLOW": "This channel is unfollowed.",
    "CHAN_MSG_ASK_UNFOLLOW": "Do you want to unfollow this channel?",
    "CHAN_MSG_OFF_NOTI": "Channel notification  is disabled.",
    "CHAN_MSG_ON_NOTI": "Channel notification is enabled."
  }
}
</i18n>
<template>
<!-- getWindowWidth, getWindowHeight -->
<!-- -->
  <div  id="alimWrap" v-if="CHANNEL_DETAIL && CHANNEL_DETAIL.D_CHAN_AUTH" ref="chanScrollWrap" style="overflow: scroll;" :style="settingBackground" class="chanDetailWrap">
      <div v-if="GE_USER.unknownYn && mUnknownLoginPopYn" style="width:100%; height: 100%; position: fixed;top: 0; left: 0; z-index: 100; background: #00000050;"></div>
      <unknownLoginPop :pClosePop="closeUnknownLoginPop" style="position: fixed;" v-if="GE_USER.unknownYn && mUnknownLoginPopYn" />
      <div id="gChannelPopup" v-if="commonChanPopShowYn" style="display: absolute; top: 0; left: 0; z-index: 999;">
        <gChannelPop :propCateItemKey="CHANNEL_DETAIL.cateKey" @openPop="openCertiPop"  :propTeamKey="CHANNEL_DETAIL.teamKey" :propPopMessage="mChanPopMessage" v-if="this.GE_USER" @closeXPop='closeChannelPop'/>
      </div>
      <smallPop v-if="smallPopYn" :confirmText='confirmMsg' :addSmallMsg='addSmallMsg' :addSmallTextYn="true" @no="smallPopYn = false" />
      <welcomePopUp type="follow" v-if="mOpenWelcomePopShowYn" :chanInfo="CHANNEL_DETAIL" @copyText="copyText" @goChanMain="mOpenWelcomePopShowYn = false" @applyMember="openReqMemPop" />
      <div v-if="mReceptMemPopShowYn" @click="closeReqMemPop" style="position: absolute; width: 100%; height: 100vh; top: 0; left: 0; background: #00000050; z-index: 99999" ></div>
      <recMemberPop :chanDetail="CHANNEL_DETAIL" v-if="mReceptMemPopShowYn" @closeXPop="closeReqMemPop" />
      <div class="font20 fontBold" :style="mChanNameLongYn ? 'font-size: 15px !important;': '' " style="color:white; line-height: 50px; position:absolute; left: 50%; transform: translateX(-50%); display:flex; max-width: calc(100% - 120px);" :class="{officialTitle: CHANNEL_DETAIL.officialYn}" > <img class="fl" src="../../../assets/images/channel/icon_official.svg" v-if="CHANNEL_DETAIL.officialYn" style="width:30px;" alt="" /> <p class="font20 fontBold textOverdot"  :style="CHANNEL_DETAIL.blackYn === 1 || CHANNEL_DETAIL.blackYn === true ? 'color:white' : 'color: rgb(74 102 158)' ">{{changeText(CHANNEL_DETAIL.nameMtext)}}</p></div>
      <div id="summaryWrap" v-if="!mChanInfoPopShowYn" class="summaryWrap" style="" >
          <div id="chanInfoSummary" ref="chanImg"  class="mt-header chanWhiteBox w100P" style="margin-bottom:0.2rem;">
            <div id="chanAlimListBG" ref="chanAlimListBG" class="chanImgRound" :style="'background-image: url(' + (CHANNEL_DETAIL.logoDomainPath ? this.CHANNEL_DETAIL.logoDomainPath + this.CHANNEL_DETAIL.logoPathMtext : this.CHANNEL_DETAIL.logoPathMtext) + ');'" style="position:absolute; left:10px;background-repeat: no-repeat; background-size: cover; background-position: center; background-color:#fff;"></div>
            <div class="w100P" style="display:flex; align-items:center; justify-content:start;">
              <div class="blankBox h100P" style="width:90px;"></div>
              <div :style="{color: CHANNEL_DETAIL.blackYn === 1 ? '#fff' : 'rgb(103, 104, 167) !important'}" class="font20" style="width:calc(100% - 170px); text-align:left;"><span>{{ $changeText(CHANNEL_DETAIL.nameMtext) }}</span></div>
              <gBtnSmall @click="changeFollowYn" v-if="!CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !GE_USER.unknownYn" class="fl w-100P fontBold font14" :btnTitle="$t('CHAN_BTN_FOLLOW')" style="width:70px; height:30px; padding:0; white-space:nowrap;"/>
              <!-- <div @click="changeFollowYn" id="followerCancelArea" v-if="CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn && CHANNEL_DETAIL.teamKey !== this.$DALIM_TEAM_KEY" class="fr" style="line-height:30px; border-radius: 5px;" :style="CHANNEL_DETAIL.D_CHAN_AUTH.followYn ? 'background-color:#DC143C' : 'background-color:#eee' " > -->
              <div @click="changeFollowYn" id="followerCancelArea" v-if="CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn" class="fr" style="line-height:30px; border-radius: 5px;" :style="CHANNEL_DETAIL.D_CHAN_AUTH.followYn ? 'background-color:#DC143C' : 'background-color:#eee' " >
                <p  class="fl font14 fontBold" style="width:70px; height:30px;" :style="CHANNEL_DETAIL.D_CHAN_AUTH.followYn ? 'color:white' : '' " >{{ $t('CHAN_BTN_UNFOLLOW') }}</p>
              </div>
              <div id="ownerChannelEditArea" class="cursorP " v-if="CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn || (CHANNEL_DETAIL.D_CHAN_AUTH.mngTeamYn === 1 && CHANNEL_DETAIL.D_CHAN_AUTH.memberNameMtext) ">
                <!-- <div v-if="GE_USER.userKey === 192 || GE_USER.userKey === 228 || GE_USER.userKey === 382" class="fr mright-05 mbottom-05" @click="getTeamToken" :class="chanBgBlackYn===true ? 'blackTextBox': 'whiteTextBox'" style="float:right !important; background-color:#DC143C; color: #FFFFFF;">
                  <p class="font14 textLeft fr" style="color: #FFFFFF!important;"  >채널아이디 발급 </p>
                </div> -->
              </div>
            </div>
          </div>
          <div class="w100P" style="height:175px; background-color:#fff; overflow:hidden; display:flex; align-items:end;">
            <div class="h100P" style="padding:10px 15px; display: flex; width: 120px; justify-content: space-around; align-items: end;">
                <!-- <div style="padding: 3px 10px; border-radius: 10px; border: 1px solid #ccc;" v-if="((CHANNEL_DETAIL.userTeamInfo && CHANNEL_DETAIL.userTeamInfo.ownerYn === undefined && CHANNEL_DETAIL.userTeamInfo.memberNameMtext === undefined) || this.$getFollowerType(CHANNEL_DETAIL.D_CHAN_AUTH) === '구독자')" >
                  <p v-if="(CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !CHANNEL_DETAIL.D_CHAN_AUTH.memberTypeKey && !CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn)" class="fl font14 cursorP fontBold commonColor" @click="this.openReqMemPop()" >{{ $t('CHAN_BTN_MEMREQ') }}</p>
                </div> -->
                <div class="favChanBox">
                  <img class="cursorP img-w20" style="height:20px; width:20px;" @click="changeRecvAlimYn" v-if="!GE_USER.unknownYn && this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn && CHANNEL_DETAIL.D_CHAN_AUTH.followYn" src="../../../assets/images/common/icon_bell_fillin.svg" alt="">
                  <img class="cursorP img-w20" style="height:20px; width:20px;" @click="changeRecvAlimYn" v-else src="../../../assets/images/common/icon_bell.svg" alt="">
                </div>
                <div class="shareChanBox" data-clipboard-action="copy" id="copyTextBody" @click="copyText"
                  :data-clipboard-text="CHANNEL_DETAIL.copyTextStr">
                  <img class="img-w20" src="../../../assets/images/common/icon_share_square.svg" alt="">
                </div>
              </div>
              <div id="channelCardWrap"  class="channelCardWrap" style="width:calc(100% - 120px); padding:10px; height:100%;">
                <div class="w100P h100P" style="overflow-y:auto; border:2px solid #ccc; display:flex; flex-direction:column; border-radius:5px; padding:10px 20px;">
                  <div class="w100P" style="display:flex; align-items:center; gap:1rem; justify-content:space-between;">
                    <div style="display:flex; gap:2rem; align-items:center;">
                      <div class="fl font14" style="width:auto;">
                        <p class="font14 textLeft fl" style="color:#000;" >
                          <img class="img-w13" style="margin-right:0.5rem" src="../../../assets/images/channel/channer_4.png" alt="구독자 아이콘">
                          {{$changeText(CHANNEL_DETAIL.cateItemMtext)}}
                        </p>
                      </div>
                      <div class="fl font14" style="width:auto; white-space:nowrap; box-sizing:boborder-box; word-break:break-all; " >
                        <p class="font14 commonColor textLeft fl fontBold " style="margin-right:0.5rem; color:rgb(74 102 158); white-space: nowrap;">{{ $t('CHAN_TITLE_CREDATE') }}</p>
                        <p class="font14 textLeft" style="width:auto; color:#000; word-break:break-all" >{{$dayjs(CHANNEL_DETAIL.creDate).format('YYYY-MM-DD')}}</p>
                      </div>
                    </div>
                      <div class="fr" @click="editChan" :class="chanBgBlackYn===true ? 'blackTextBox': 'whiteTextBox'" style="padding:0; float:right !important; ">
                          <p class="font14 textLeft fr" style="color:#000;">{{ $t('CHAN_BTN_EDIT') }} > </p>
                        </div>
                    </div>
                    <div class="chanTextBox" style="width:100%;">
                      <div class="fl font15 w-100P " style="box-sizing:border-box; word-break:break-all; " >
                        <p class="font14 textLeft fl " style="color:#000; word-break:break-all" >
                          <!-- <img class="fl img-w20" style="margin-top:2px; margin-right:1rem" src="../../../assets/images/channel/channer_3.png" alt="채널 메세지 아이콘"> -->
                          {{$changeText(CHANNEL_DETAIL.memoMtext)}}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div id="userCardWrap" class="w-100P" style="flex-direction: row; justify-content: space-between;">
                        <div class="w100P" style="padding-top:0.3rem; display: flex; align-items: center;">
                          <gProfileImg :smallYn="true" :selfYn="true" style="width: 30px; height:30px; margin-right: 10px;" />
                          <div style="display:flex; align-items:center; gap:0.3rem;">
                            <p @click="goProfile" class="font14 textLeft" style="color:#000; ">{{GE_USER.userDispMtext? $changeText(GE_USER.userDispMtext) : $t('CHAN_NAME_GUEST')}}</p>
                            <div>
                              <p class="fl font14 textLeft commonBlack">{{$getFollowerType(CHANNEL_DETAIL.D_CHAN_AUTH)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100P" :class="chanBgBlackYn===true ? 'blackTextBox': 'whiteTextBox'" style="padding-top:2rem; flex-direction: row; justify-content:space-around">
                      <p class="font14 w-100P">{{ GE_LOCALE === 'ko'? `구독자 ${CHANNEL_DETAIL.followerCount}명`:`${CHANNEL_DETAIL.followerCount} Followers` }}</p>
                      <p class="font14 w-100P" style="border-left: 2px solid #00000050">{{GE_LOCALE === 'ko'? `누적 쪽지 ${CHANNEL_DETAIL.totalContentsCount}`:`${CHANNEL_DETAIL.totalContentsCount} Total Contents` }}</p>
                    </div>
                </div>
              </div>
            <!-- -->
          </div>
          <!-- <div id="chanInfoSummary" style=" width:100%; height:370px; position: relative; background-size: 100% 100%; ">
            <div style="width:100%; height:100%; background-size: 100% 100%; " ></div>
            <div style=" position:absolute; bottom:-37%; left: 5%;">
              <div style="float: left; width:140px">
                <div style=" background-color: #FFF;  padding: 5px; border-radius: 110px; border-radius: 100%;">
                  <div id="chanAlimListBG" ref="chanAlimListBG" class="chanImgRound" :style="'background-image: url(' + (this.CHANNEL_DETAIL.logoDomainPath ? this.CHANNEL_DETAIL.logoDomainPath + this.CHANNEL_DETAIL.logoPathMtext : this.CHANNEL_DETAIL.logoPathMtext) + ');'" style="background-repeat: no-repeat; background-size: cover; background-position: center; background-color:white;" ></div>
                </div>
                <div style=" display: flex;  justify-content: center; margin-top: 5px;">
                  <div style="width: 40px; height: 40px; margin-right:15px ; background: #F1F1FF; border-radius: 30px; float:left; display: flex; justify-content: center; align-items: center;" @click="changeRecvAlimYn">
                    <img class="cursorP" width="20" height="20" v-if="!mUnknownYn && this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn" src="../../../assets/images/common/icon_bell_fillin.svg" alt="">
                    <img class="cursorP" width="20" height="20" v-else-if="!mUnknownYn && !this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn" src="../../../assets/images/common/icon_bell.svg" alt="">
                  </div>
                  <div style="width: 40px; height: 40px; background: #F1F1FF; border-radius: 30px; float:left; display: flex; justify-content: center; align-items: center;" data-clipboard-action="copy" id="copyTextBody" @click="copyText" :data-clipboard-text="CHANNEL_DETAIL.copyTextStr">
                    <img src='../../../assets/images/contents/icon_share.png' width="20" height="20"/>
                  </div>
                </div>
              </div>
              <div style="float: left; width:calc(100% - 140px); height:200px; padding:15px 20px; box-sizing:border-box; word-break:break-all">
                <div style="width:100%; height:40px; font-size: 25px; font-weight: 800; text-align: left; color:#FFF; padding:5px 0">[MANKIK]만끽프로젝트</div>
                  <div style=" width:100%; text-align:left; font-size: 15px; padding:10px 0; word-break:break-all">{{this.$changeText(CHANNEL_DETAIL.memoMtext)}}</div>
                  <div style=" width:100%;height:30px">
                    <div style=" margin-right:20px; float: left; line-height: 25px; ">
                      <img src='../../../assets/images/main/icon_channel.png' width="20" height="25" class="fl"/>
                      <div class="fl" style="line-height: 25px;  padding-left: 5px;">{{this.$changeText(this.CHANNEL_DETAIL.cateItemMtext)}}</div>
                    </div>
                    <div style="float: left; ">
                      <img src='../../../assets/images/channel/icon_calender.png' width="20" height="25" class="fl"/>
                      <div class="fl" style="line-height: 25px; padding-left: 5px;">{{this.$dayjs(CHANNEL_DETAIL.creDate).format('YYYY-MM-DD')}}</div>
                    </div>
                  </div>
                  <div style=" width:100% ;height:30px">
                    <div style="float: left; margin-right:20px">구독자 <span style="color:black; font-weight: 1000;">{{CHANNEL_DETAIL.followerCount}}명</span></div>
                    <div style="float: left;">누적알림 <span style="color:black; font-weight: 1000;">{{CHANNEL_DETAIL.totalContentsCount}}건</span></div>
                  </div>
                  <div style=" width:100%; height:30px">
                    <div style="float: left; margin-right:10px; color: #2D7BEF;">구독 중</div>
                    <div style="float: left;">{{this.$getFollowerType(CHANNEL_DETAIL.D_CHAN_AUTH)}}</div>
                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 60px; height: 25px;border: 2px solid rgb(74 102 158); border-radius: 10px; color: #6768A8; font-size: 12px;" @click="this.openReqMemPop()">
                      멤버신청
                    </div>
                  </div>
              </div>
            </div>
          </div> -->
      </div>

      <div class="channelItemBox" ref="channelItemBoxPushListDivCompo" id="channelItemBox"  style="border-top:2px solid rgb(238, 238, 238); margin-top: 257px; background: rgb(220, 221, 235); padding-top: 0; overflow: hidden;">
          <pushList :pUnknownYn="mUnknownYn" @openImgPop="openImgPop" @goScroll="this.mChanMainScrollWrap.style.overflow = 'scroll'" :initData="this.chanDetail.initData.contentsList" @cMemoEditYn="changeMemoEditYn" :targetContents="{targetContentsKey : chanDetail.targetContentsKey, jobkindId : chanDetail.jobkindId }" :chanAlimYn="true" :pChannelDetail="this.CHANNEL_DETAIL" :chanAlimTargetType="this.chanDetail.targetType" ref="ChanAlimListPushListCompo" :alimListYn="true" @openPop="openPushDetailPop" style="" :chanDetailKey="this.CHANNEL_DETAIL.teamKey" @numberOfElements='numberOfElements' @targetContentScrollMove='targetContentScrollMove' @openLoading="this.$emit('openLoading')" @closeLoading="this.$emit('closeLoading')" @openUserProfile='openItem' @changeMainTab='changeMainTab' isOpen='chanAlim' @memoEdit='memoEdit'/>
      </div>

      <div v-if="this.mChanInfoPopShowYn" >
        <chanDetailComp ref="chanDetailRef" @openLoading="this.$emit('openLoading')" @closeLoading="this.$emit('closeLoading')" @closeXPop="this.closeDetailPop" @changeshowProfileYn='changeshowProfileYn' :parentshowProfileYn="CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn" :adminYn="CHANNEL_DETAIL.D_CHAN_AUTH.adminYn" :alimSubPopYn="alimListToDetail" @pageReload="this.$emit('pageReload', true)" @openPop="openPushDetailPop" @closeDetailPop="this.closeDetailPop" @changeFollowYn="changeFollowYn" :chanDetail="this.CHANNEL_DETAIL" style="background-color: #fff;"></chanDetailComp>
      </div>
      <img id='writeBtn' src="../../../assets/images/button/Icon_WriteAlimBtn.png" @click="openWritePushPop" alt="알림 작성 버튼" style="position: absolute; bottom: 2rem; right: 10%; z-index:9; cursor: pointer;" class="img-78 img-w66">
      <!-- <img id='writeBtn' src="../../../assets/images/button/Icon_WriteAlimBtn.png" v-if="CHANNEL_DETAIL.D_CHAN_AUTH && (CHANNEL_DETAIL.D_CHAN_AUTH.memberNameMtext || CHANNEL_DETAIL.D_CHAN_AUTH.memberYn === 1)  && (CHANNEL_DETAIL.D_CHAN_AUTH.mngAlimYn === 1 || CHANNEL_DETAIL.D_CHAN_AUTH.mngAlimYn === true) && (mPushListMainTab === 'P' || mPushListMainTab === 'A') && this.mWriteBtnShowYn" @click="openWritePushPop" alt="알림 작성 버튼" style="position: absolute; bottom: 2rem; right: 10%; z-index:9" class="img-78 img-w66"> -->
      <!-- <img id='writeBtn' src="../../../assets/images/button/Icon_WriteBoardBtn.png" v-if="mPushListMainTab === 'B' && this.mWriteBtnShowYn" @click="openWritePushPop" alt="게시글 작성 버튼" style="position: absolute; bottom: 2rem; right: 10%; " class="img-78 img-w66"> -->
      <gConfirmPop :confirmText='mErrorPopBodyStr' :confirmType='mErrorPopBtnType' @no='mErrorPopShowYn=false' v-if="mErrorPopShowYn" @ok="confirmOk"/>
      <div v-if="writeBottSheetYn" @click="writeBottSheetYn = false" style="width: 100%; height: 100%; position: absolute; z-index: 10; left: 0; top: 0; background: #00000030;"></div>
      <transition name="showUp">
        <writeBottSheet v-if="writeBottSheetYn === true" @openPop='openWriteContentsPop' @closePop='writeBottSheetYn = false' :propTeamKey='CHANNEL_DETAIL.teamKey' @openMember='openChannelMsgPop()' />
      </transition>
  </div>
  <div v-if="writeContentsYn === true" style="position: absolute; top:0; left:0; z-index:10; background:#00000050; width: 100vw; min-height: 100vh;" class=""></div>
  <writeContents v-if="writeContentsYn === true"  ref="chanAlimListWritePushRefs" :contentType="mPushListMainTab === 'P' ? 'ALIM' : 'BOAR'" @closeXPop='closeWritePushPop' :params="mWriteAlimData"  @openPop='openItem' :changeMainTab='changeMainTab' @toAlimFromBoard='toAlimFromBoard' :propData="mWriteBoardData" />
</template>

<script>
/* eslint-disable vue/no-async-in-computed-properties */
import chanDetailComp from './Tal_chanDetail.vue'
import pushList from '../../../pages/routerPages/Tal_pushList.vue'
import welcomePopUp from './Tal_chanFollowInfo.vue'
import writeContents from '../../popup/D_writeContents.vue'
import { onMessage } from '../../../assets/js/webviewInterface'
import recMemberPop from '../../popup/member/D_recMemberPop.vue'
// import boardWrite from '../../board/Tal_boardWrite.vue'
import writeBottSheet from '../../pageComponents/main/unit/D_contentsWriteBottSheet.vue'
import unknownLoginPop from '@/components/pageComponents/channel/D_unknownLoginPop.vue'
export default {
  data () {
    return {
      mUnknownLoginPopYn: false,
      mUnknownYn: false,
      smallPopYn: false,
      alimListToDetail: false,
      mChanMainScrollWrap: null,
      mChanMainScrollDirection: null,
      mChanMainScrollPosition: null,
      mErrorPopShowYn: false,
      mErrorPopBodyStr: '',
      mErrorPopBtnType: 'two',
      mChanInfoPopShowYn: false,
      myContentsCount: null,
      mSaveFollowerParam: null,
      mOpenWelcomePopShowYn: false,
      mWriteAlimData: {},
      writeContentsYn: false,
      writeAlimPopId: '',
      mChanNameLongYn: false,
      mSaveFollowerType: '',
      mPushListMainTab: 'A',
      axiosQueue: [],
      mWriteBoardData: {},
      mWriteBoardPopId: '',
      mReceptMemPopShowYn: false,
      mWriteBtnShowYn: true,
      mMakeDeepLinkIng: false,
      mChanPopMessage: '',
      commonChanPopShowYn: false,
      writeBottSheetYn: false,
      mwWidth: 0,
      mwHeight: 0,
      prevVisualViewport: 0,
      mDirectTeamKey: null,
      // errorPopYn: false,
      mTitleColor: 0
    }
  },
  props: {
    chanDetail: {},
    notiScrollTarget: {},
    pPopId: {},
    popYn: { type: Boolean, default: false }
  },
  components: {
    writeBottSheet,
    pushList,
    chanDetailComp,
    welcomePopUp,
    writeContents,
    recMemberPop,
    unknownLoginPop
  },
  created () {
    this.$emit('openLoading')
    this.readyFunction()
    console.log('<<<CHANNEL_DETAIL>>>', this.CHANNEL_DETAIL)
  },
  // updated () {
  //   this.mChanMainScrollWrap = this.$refs.chanScrollWrap
  //   if (this.mChanMainScrollWrap) {
  //     this.mChanMainScrollWrap.addEventListener('scroll', this.updateScroll)
  //     this.mChanMainScrollWrap.addEventListener('mousewheel', e => {
  //       this.mChanMainScrollDirection = e.deltaY > 0 ? 'down' : 'up'
  //     })
  //   }
  // },
  mounted () {
    this.mChanMainScrollWrap = this.$refs.chanScrollWrap
    if (this.mChanMainScrollWrap) {
      this.mChanMainScrollWrap.addEventListener('scroll', this.updateScroll)
      this.mChanMainScrollWrap.addEventListener('mousewheel', e => {
        this.mChanMainScrollDirection = e.deltaY > 0 ? 'down' : 'up'
      })
    }
    this.setWindowSize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount () {
    // window.removeEventListener('resize', () => { this.setWindowSize() })
  },
  methods: {
    async getTeamToken () {
      if (this.CHANNEL_DETAIL.teamId) {
        console.log(this.CHANNEL_DETAIL.teamId)
      } else {
        var result = await this.$commonAxiosFunction({
          url: 'https://www.hybric.net:9443/service/tp.getAndSaveTeamAESToken',
          param: { teamKey: this.CHANNEL_DETAIL.teamKey }
        })
        console.log(result.data)
      }
    },
    closeUnknownLoginPop () {
      this.mUnknownLoginPopYn = false
    },
    openUnknownLoginPop () {
      this.mUnknownLoginPopYn = true
    },
    openImgPop (param) {
      this.$emit('openImgPop', param)
    },
    penImgPop (param) {
      this.$emit('openImgPop', param)
    },
    openCertiPop (param) {
      console.log(param)
      this.$emit('openPop', param)
    },
    setWindowSize () {
      this.mwWidth = window.innerWidth
      var nowHeight = window.innerHeight

      if (this.mwHeight < nowHeight) this.mwHeight = nowHeight
    },
    handleResize (event) {
      if (this.$checkMobile() === 'IOS') {
      } else {
        this.setWindowSize()
      }
      // emitEvent.call(event, eventName)
    },
    openChannelMsgPop () {
      this.writeBottSheetYn = false
      this.commonChanPopShowYn = true
    },
    openWriteContentsPop (openPopParam) {
      this.writeBottSheetYn = false
      this.$emit('openPop', openPopParam)
    },
    async closeChannelPop (resultReqData) {
      this.commonChanPopShowYn = false
      if (resultReqData) {
        var memberInfoText = ''
        if (resultReqData.memberYn && resultReqData.memberType) {
          if (this.GE_LOCALE === 'ko') {
            memberInfoText += '환영합니다!<br> "'
            memberInfoText += this.$changeText(resultReqData.memberType.nameMtext)
            memberInfoText += '" 멤버가 되었습니다<br>'
          } else {
            memberInfoText += 'Welcome!<br>You become a "'
            memberInfoText += this.$changeText(resultReqData.memberType.nameMtext)
            memberInfoText += '" member.<br>'
          }
          if (resultReqData.memberType.initData && resultReqData.memberType.initData.length > 0) {
          /* this.mReqResultMsg += '<br><p class="textLeft commonColor font14 fontBold">부가정보</p>' */
            memberInfoText += '('
            for (var i = 0; i < resultReqData.memberType.initData.length; i++) {
              memberInfoText += (i !== 0 ? ' ' : '')
              memberInfoText += this.$changeText(resultReqData.memberType.initData[i].itemNameMtext) + '  ' + resultReqData.memberType.initData[i].value
              memberInfoText += (i === resultReqData.memberType.initData.length - 1 ? ')' : '')
            }
          }
          // memberInfoText += '"'
          // memberInfoText += '<br>정상적으로 신청되었습니다!'
        } else {
          memberInfoText += this.$t('CHAN_MSG_AGAIN_MEMBER')
        }

        this.mErrorPopBodyStr = memberInfoText
        this.mErrorPopBtnType = 'one'
        this.mErrorPopShowYn = true
        await this.$addChanList(this.chanDetail.targetKey)
      }
    },
    targetContentScrollMove (wich, jobkindId) {
      if (wich && jobkindId) {
        var tabName = 'P'
        if (jobkindId === 'BOAR') {
          tabName = 'B'
        }
        this.changeMainTab(tabName)
        this.$refs.ChanAlimListPushListCompo.changeMainTab(tabName)
      }
      if (wich || this.chanDetail.targetContentsKey) {
        const unit = this.$refs.chanScrollWrap
        unit.scrollTo({ top: 500, behavior: 'smooth' })
        var blockBox = document.getElementById('summaryWrap')
        blockBox.style.height = 50 + 'px'
        // document.getElementById('chanInfoSummary').classList.add('displayNIm')

        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !this.CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn && this.CHANNEL_DETAIL.teamKey !== this.$DALIM_TEAM_KEY) document.getElementById('followerCancelArea').classList.add('displayNIm')
        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn && document.getElementById('ownerChannelEditArea')) document.getElementById('ownerChannelEditArea').classList.add('displayNIm')
        document.getElementById('channelCardWrap').classList.add('displayNIm')
        document.getElementById('userCardWrap').classList.add('displayNIm')
        document.getElementById('channelItemBox').classList.add('channelItemBoxHeight')
      }
    },
    openReqMemPop () {
      this.openChannelMsgPop()
      // this.commonChanPopShowYn = true
      // if (this.CHANNEL_DETAIL.cateKey !== 3) {
      //   this.mReceptMemPopShowYn = true
      // } else {
      //   this.commonChanPopShowYn = true
      // }
    },
    async closeReqMemPop (yn) {
      // 그냥 닫기 눌렀을 때 환영합니다. 팝업이 등장하기 위해 그냥 닫으면 false가 오고 신청을 누르면 아무것도  오기에 undefind가 뜰 것 입니다.
      if (yn === false) {
      } else {
        await this.readyFunction()
        this.mOpenWelcomePopShowYn = false
      }
      this.mReceptMemPopShowYn = false
    },
    memoEdit (editYn) {
      var btn = document.getElementById('writeBtn')
      if (editYn === true) {
        btn.style.display = 'none'
      } else {
        btn.style.display = 'block'
      }
    },
    changeMemoEditYn (memoEditYn) {
      this.mWriteBtnShowYn = memoEditYn
    },
    toAlimFromBoard (tab) {
      this.$refs.ChanAlimListPushListCompo.changeMainTab(tab)
    },
    changeMainTab (tab) {
      this.mPushListMainTab = tab
    },
    goProfile () {
      // eslint-disable-next-line no-new-object
      var param = new Object()
      param.targetType = 'bookMemberDetail'
      param.readOnlyYn = true
      param.selfYn = true
      param.popHeaderText = this.$t('COMMON_NAME_MY_INFO')
      this.$emit('openPop', param)
    },
    async readyFunction () {
      // eslint-disable-next-line no-debugger
      debugger
      const directUrl = localStorage.getItem('urlString')
      if (directUrl) {
        const directUrlObj = JSON.parse(directUrl)
        if (directUrlObj.teamKey) {
          const chanDetail = await this.$addChanList(directUrlObj.teamKey)
          // eslint-disable-next-line vue/no-mutating-props
          this.mDirectTeamKey = chanDetail
        }
      } else {
        this.$showAxiosLoading(true)
        if (this.chanDetail.initData) {
          if (this.chanDetail.initData.team.D_CHAN_AUTH && this.chanDetail.initData.team.D_CHAN_AUTH.followYn) {
            this.$emit('followYn')
          }
          if (this.CHANNEL_DETAIL) {
            console.log(this.chanDetail.initData.team.copyTextStr === undefined)
            if ((this.chanDetail.initData.team.copyTextStr === undefined && this.CHANNEL_DETAIL.copyTextStr === undefined) && !this.mMakeDeepLinkIng) {
              this.mMakeDeepLinkIng = true
              var title = `[${this.$t('COMMON_APP_NAME')}] ` + this.$changeText(this.CHANNEL_DETAIL.nameMtext)
              var message = this.$changeText(this.CHANNEL_DETAIL.memoMtext)
              var this_ = this
              this.$makeShareLink(this.CHANNEL_DETAIL.teamKey, 'chanDetail', message, title).then(res => {
                this.CHANNEL_DETAIL.copyTextStr = res
                this_.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', [this.CHANNEL_DETAIL])
                this_.mMakeDeepLinkIng = false
              })
            }
          }
        }
      }

      this.$emit('closeLoading')
      this.$showAxiosLoading(false)
      /* if (this.CHANNEL_DETAIL && this.CHANNEL_DETAIL.userTeamInfo && (this.CHANNEL_DETAIL.userTeamInfo.memberInfoList.length === 0 || !this.CHANNEL_DETAIL.userTeamInfo.memberInfoList[0].memberTypeKey)) this.commonChanPopShowYn = true */
    },
    setSelectedList (data) {
      this.$refs.chanAlimListWritePushRefs.setSelectedList(data)
    },
    openItem (param) {
      this.$emit('openPop', param)
    },
    editChan () {
      var param = {}
      param.targetType = 'createChannel'
      param.popHeaderText = this.$t('EDIT_NAME_CHAN')
      param.targetKey = this.CHANNEL_DETAIL.teamKey
      param.modiYn = true
      param.ownerYn = this.CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn
      this.$emit('openPop', param)
    },
    reloadPage () {
      this.readyFunction()
    },
    async okMember () {
      var result = null
      this.$emit('openLoading')
      result = await this.$changeFollower({ follower: this.mSaveFollowerParam, doType: 'CR' }, 'save')
      if (result.result || result) {
        if (result.message === 'OK') {
          this.mOpenWelcomePopShowYn = false
          // await this.$addChanList(this.CHANNEL_DETAIL.teamKey)
          // var queueIndex = this.axiosQueue.findIndex((item) => item === 'addChanList')
          // this.axiosQueue = this.axiosQueue.splice(queueIndex, 1)

          // this.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', this.CHANNEL_DETAIL)
          // this.$emit('changeFollowYn', this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn)
        } else {
          this.errorMsg = result.message
          this.errorPopYn = true
        }
      } else {
        this.errorMsg = this.$t('COMMON_MSG_FAILED')
        this.errorPopYn = true
      }

      this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn = true
      this.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', [this.CHANNEL_DETAIL])
      /* this.$actionVuex('TEAM', this.CHANNEL_DETAIL, this.CHANNEL_DETAIL.teamKey, false, true) */
      this.$emit('closeLoading')
    },
    async confirmOk () {
      this.mErrorPopShowYn = false
      if (this.mSaveFollowerType === 'follow') { // follow를 눌렀을 때 내가 관리자면 에러팝업
        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.admYn === true) {
          this.mErrorPopBodyStr = this.$t('CHAN_MSG_NOCANCEL')
          this.mErrorPopShowYn = true
          this.mErrorPopBtnType = 'two'
        } else { // follow를 눌렀고 내가 관리자가 아닐 때
          var fStatus = this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn
          console.log('fStatus?', fStatus)
          // eslint-disable-next-line no-new-object
          this.mSaveFollowerParam = new Object()
          this.mSaveFollowerParam.targetKind = 'T'
          this.mSaveFollowerParam.targetKey = this.CHANNEL_DETAIL.teamKey
          this.mSaveFollowerParam.teamName = this.$changeText(this.CHANNEL_DETAIL.nameMtext)
          this.mSaveFollowerParam.userKey = this.$store.getters['D_USER/GE_USER'].userKey
          this.mSaveFollowerParam.userName = this.$changeText(this.GE_USER.userDispMtext)
          // console.log(this.mSaveFollowerParam)
          var result = false
          if (fStatus) {
            // console.log(this.mSaveFollowerParam)
            if (this.axiosQueue.findIndex((item) => item === 'changeFollower') !== -1) return
            this.axiosQueue.push('changeFollower')
            result = await this.$changeFollower({ follower: this.mSaveFollowerParam, doType: 'CR' }, 'del')
            var queueIndex = this.axiosQueue.findIndex((item) => item === 'changeFollower')
            // this.axiosQueue = this.axiosQueue.splice(queueIndex, 1)
            this.axiosQueue.splice(queueIndex, 1)
            this.CHANNEL_DETAIL.D_CHAN_AUTH = null
            this.CHANNEL_DETAIL.followerKey = null
            this.CHANNEL_DETAIL.userTeamInfo = null
            this.CHANNEL_DETAIL.followerCount -= 1
            // console.log(this.CHANNEL_DETAIL)
            this.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', [this.CHANNEL_DETAIL])
            // this.$actionVuex('TEAM', this.CHANNEL_DETAIL, this.CHANNEL_DETAIL.teamKey, false, true)
            // console.log(result)

            this.$emit('showToastPop', this.$t('CHAN_MSG_UNFOLLOW'))

            if (result.result || result) {
              this.$emit('pageReload')
            } else {
              this.mErrorPopBodyStr = this.$t('COMMON_MSG_FAILED')
              this.mErrorPopBtnType = 'timeover'
              this.mErrorPopShowYn = true
            }
          } else {
            await this.okMember()
            if (this.GE_LOCALE === 'ko') {
              this.mChanPopMessage = '[' + this.$changeText(this.CHANNEL_DETAIL.nameMtext) + '] 채널의 구독자가 되었습니다.<br>멤버가 되면<br>우리채널에 알림을 보낼 수 있어요!<br>멤버들끼리 자유롭게 소통할 수 있어요!'
            } else {
              this.mChanPopMessage = `You have become a follower to the [${this.$changeText(this.CHANNEL_DETAIL.nameMtext)}] channel.<br>If you become a member, you can send notifications to our channel!<br>Members can communicate freely!`
            }
            this.openChannelMsgPop()
          }
        }
      }
    },
    changeFollowYn () {
      this.mSaveFollowerType = 'follow'
      if (this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn === true) {
        this.mErrorPopBodyStr = this.$t('CHAN_MSG_ASK_UNFOLLOW')
        this.mErrorPopBtnType = 'two'
        this.mErrorPopShowYn = true
      } else {
        this.confirmOk()
      }
    },
    async copyText () {
      if ((this.chanDetail.initData.team.copyTextStr === undefined && this.CHANNEL_DETAIL.copyTextStr === undefined) && !this.mMakeDeepLinkIng) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mMakeDeepLinkIng = true
        var title = `[${this.$t('COMMON_APP_NAME')}] ` + this.$changeText(this.CHANNEL_DETAIL.nameMtext)
        var message = this.$changeText(this.CHANNEL_DETAIL.memoMtext)
        var this_ = this
        this.$makeShareLink(this.CHANNEL_DETAIL.teamKey, 'chanDetail', message, title).then(res => {
          this.CHANNEL_DETAIL.copyTextStr = res
          this_.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', [this.CHANNEL_DETAIL])
          this_.mMakeDeepLinkIng = false
        })
      }
      var shareItem = { title: `[${this.$t('COMMON_APP_NAME')}] ` + this.$changeText(this.CHANNEL_DETAIL.nameMtext), text: this.CHANNEL_DETAIL.copyTextStr, url: this.CHANNEL_DETAIL.copyTextStr }
      if (this.$checkMobile() === 'IOS') {
        shareItem = { title: `[${this.$t('COMMON_APP_NAME')}]` + this.$changeText(this.CHANNEL_DETAIL.nameMtext), text: `[${this.$t('COMMON_APP_NAME')}] ` + this.$changeText(this.CHANNEL_DETAIL.nameMtext), url: this.CHANNEL_DETAIL.copyTextStr }
      }
      if (navigator.share) {
        navigator.share(shareItem)
      } else {
        onMessage('REQ', 'nativeShare', shareItem)
      }
    },
    async changeRecvAlimYn () {
      // console.log(this.axiosQueue)
      if (this.axiosQueue.findIndex(item => item === 'changeRecvAlimYn') !== -1) return
      // eslint-disable-next-line no-new-object
      var param = new Object()
      param.followerKey = this.CHANNEL_DETAIL.userTeamInfo.followerKey
      var toastText = ''
      if (this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn === 1) {
        this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn = false
        param.notiYn = this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn
        toastText = this.$t('CHAN_MSG_OFF_NOTI')
      } else {
        this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn = true
        param.notiYn = this.CHANNEL_DETAIL.D_CHAN_AUTH.notiYn
        toastText = this.$t('CHAN_MSG_ON_NOTI')
      }
      this.axiosQueue.push('changeRecvAlimYn')
      this.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', [this.CHANNEL_DETAIL])

      /* this.$actionVuex('TEAM', this.CHANNEL_DETAIL, this.CHANNEL_DETAIL.teamKey, false, true) */
      await this.$changeRecvAlimYn({ follower: param })
      await this.$addChanList(this.CHANNEL_DETAIL.teamKey).then(() => {
        var queueIndex = this.axiosQueue.findIndex(item => item === 'changeRecvAlimYn')
        this.axiosQueue.splice(queueIndex, 1)
      })
      this.CHANNEL_DETAIL.copyTextStr = this.chanDetail.initData.team.copyTextStr
      this.$store.dispatch('D_CHANNEL/AC_ADD_CHANNEL', [this.CHANNEL_DETAIL])
      setTimeout(() => {
        this.$showToastPop(toastText)
      }, 500)
    },
    numberOfElements (num) {
      this.myContentsCount = num
    },
    closeDetailPop () {
      var history = this.$store.getters['D_HISTORY/hStack']
      var removePage = history[history.length - 1]
      history = history.filter((element, index) => index < history.length - 1)
      this.$store.commit('D_HISTORY/setRemovePage', removePage)
      this.$store.commit('D_HISTORY/updateStack', history)
      this.mChanInfoPopShowYn = false
    },
    openWritePushPop () {
      if (this.GE_USER.unknownYn) {
        this.openUnknownLoginPop()
        // this.$showToastPop('로그인 후 이용해주세요')
        return
      }
      this.writeBottSheetYn = true
      // var history = this.$store.getters['D_HISTORY/hStack']
      // if (this.mPushListMainTab === 'A') {
      //   this.writeBottSheetYn = true
      //   return
      // }
      // if (this.mPushListMainTab === 'P') {
      // // eslint-disable-next-line no-new-object
      //   var params = new Object()
      //   params.targetKey = this.CHANNEL_DETAIL.teamKey
      //   params.targetType = 'writeContents'
      //   params.targetNameMtext = this.CHANNEL_DETAIL.nameMtext
      //   params.contentsJobkindId = 'ALIM'
      //   this.mWriteAlimData = {}
      //   this.mWriteAlimData = params
      //   this.writeAlimPopId = 'writeContents' + history.length
      //   // eslint-disable-next-line no-debugger
      //   debugger
      //   this.writeAlimPopId = this.$setParentsId(this.pPopId, this.writeAlimPopId)
      //   history.push(this.writeAlimPopId)
      //   // this.$store.commit('D_HISTORY/updateStack', history)
      //   // this.writeContentsYn = true
      // } else if (this.mPushListMainTab === 'B') {
      //   var param = {}
      //   param.targetType = 'writeContents'
      //   param.selectBoardYn = true
      //   param.contentsJobkindId = 'BOAR'
      //   param.teamKey = this.CHANNEL_DETAIL.teamKey
      //   param.targetKey = this.CHANNEL_DETAIL.teamKey
      //   param.currentTeamKey = this.CHANNEL_DETAIL.teamKey
      //   this.mWriteBoardData = {}
      //   this.mWriteBoardData = param
      //   history = this.$store.getters['D_HISTORY/hStack']
      //   this.mWriteBoardPopId = 'writeContents' + history.length
      //   this.mWriteBoardPopId = this.$setParentsId(this.pPopId, this.mWriteBoardPopId)
      //   history.push(this.mWriteBoardPopId)

      //   // this.$emit('openPop', param)
      // }
      // this.writeContentsYn = true
      // this.$store.commit('D_HISTORY/updateStack', history)
      // this.$emit('openPop', params)
    },
    openPushDetailPop (param) {
      // eslint-disable-next-line no-debugger
      debugger
      if (param.targetType === 'contentsDetail' || param.targetType === 'createChannel') {
        param.openActivity = 'chanAlimList'
      }
      if (param.targetType === 'createChannel') {
        param.popHeaderText = this.$t('EDIT_NAME_CHAN')
      } else if (param.targetType === 'chanDetail') {
        if (param.targetKey === this.CHANNEL_DETAIL.teamKey) {
          return
        }
      }
      this.$emit('openPop', param)
    },
    changeText (text) {
      var changeTxt = ''
      // changeTxt = new Promise(this.$makeMtextMap(text, 'KO'))
      changeTxt = this.$makeMtextMap(text, 'KO')
      if (changeTxt) {
        if (changeTxt.length > 12) { this.mChanNameLongYn = true }
        return changeTxt
      }
    },
    backClick () {
      var hStack = this.$store.getters['D_HISTORY/hStack']
      var history = this.$store.getters['D_HISTORY/hStack']
      var removePage = history[history.length - 1]
      if (this.writeAlimPopId === hStack[hStack.length - 1]) {
        history = history.filter((element, index) => index < history.length - 1)
        this.$store.commit('D_HISTORY/setRemovePage', removePage)
        this.$store.commit('D_HISTORY/updateStack', history)
        this.closeWritePushPop()
      } else if (this.mWriteBoardPopId === hStack[hStack.length - 1]) {
        // var history = this.$store.getters['D_HISTORY/hStack']
        // var removePage = history[history.length - 1]
        history = history.filter((element, index) => index < history.length - 1)
        this.$store.commit('D_HISTORY/setRemovePage', removePage)
        this.$store.commit('D_HISTORY/updateStack', history)
        this.closeWritePushPop()
      }
    },
    async closeWritePushPop (reloadYn) {
      this.writeContentsYn = false
    },
    openPop () {
      this.alimListToDetail = true
      // thiopenPop.mChanInfoPopShowYn = true
    },
    updateScroll () {
      var blockBox = document.getElementById('summaryWrap')
      if (this.mChanMainScrollWrap.scrollTop > this.mChanMainScrollPosition) {
        this.mChanMainScrollDirection = 'down'
      } else if (this.mChanMainScrollWrap.scrollTop < this.mChanMainScrollPosition) {
        this.mChanMainScrollDirection = 'up'
      }

      this.mChanMainScrollPosition = this.mChanMainScrollWrap.scrollTop
      if (this.mChanMainScrollDirection === 'down' && this.mChanMainScrollPosition > 150) {
        blockBox.style.height = 50 + 'px'
        if (this.mChanMainScrollPosition > 290) this.mChanMainScrollWrap.style.overflow = 'hidden'

        // document.getElementById('chanInfoSummary').classList.add('displayNIm')

        // 하이브릭 채널은 구독취소버튼이 없으므로 아래의 클래스가 v-if에 의해 생성되지 않으므로 에러가 나기에 추가함
        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !this.CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn && this.CHANNEL_DETAIL.teamKey !== this.$DALIM_TEAM_KEY) document.getElementById('followerCancelArea').classList.add('displayNIm')

        if (document.getElementById('ownerChannelEditArea')) document.getElementById('ownerChannelEditArea').classList.add('displayNIm')

        document.getElementById('channelCardWrap').classList.add('displayNIm')
        document.getElementById('userCardWrap').classList.add('displayNIm')

        document.getElementById('channelItemBox').classList.add('channelItemBoxHeight')
      } else if (this.mChanMainScrollDirection === 'up' && this.mChanMainScrollPosition < 300) {
        document.getElementById('chanInfoSummary').classList.remove('displayNIm')
        // document.getElementById('chanInfoArea').classList.remove('displayNIm')
        // document.getElementById('memberInfoArea').classList.remove('displayNIm')
        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.followYn && !this.CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn && this.CHANNEL_DETAIL.teamKey !== this.$DALIM_TEAM_KEY) document.getElementById('followerCancelArea').classList.remove('displayNIm')

        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.ownerYn) document.getElementById('ownerChannelEditArea').classList.remove('displayNIm')

        document.getElementById('channelCardWrap').classList.remove('displayNIm')
        document.getElementById('userCardWrap').classList.remove('displayNIm')

        blockBox.style.height = '260px'
        this.mChanMainScrollWrap.style.height = ''
        // document.getElementById('chanInfoSummary2').classList.remove('displayBIm')
        document.getElementById('channelItemBox').classList.remove('channelItemBoxHeight')
      }
    }
    /* changeshowProfileYn (data) {
      this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn = data
    }, */
    /* async saveMemberButton () {
      if (this.axiosQueue.findIndex((item) => item === 'saveMemberButton') !== -1) return
      this.smallPopYn = true
      if (this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn || this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn === 1) {
        this.confirmMsg = '내 정보 공개가 취소 완료되었습니다.'
        this.addSmallMsg = '(언제든 다시 ' + this.$changeText(this.CHANNEL_DETAIL.nameMtext) + ' 의 정보 공개를 할 수 있습니다.)'
      } else {
        this.confirmMsg = '내 정보 공개가 완료되었습니다.'
        this.addSmallMsg = '(관리자는 당신의 프로필 정보를 조회할 수 있습니다.)'
      }
      var params = null
      var param = {}
      param.followerKey = this.CHANNEL_DETAIL.userTeamInfo.followerKey
      param.teamKey = this.CHANNEL_DETAIL.teamKey
      param.userName = this.$changeText(this.GE_USER.userDispMtext) || this.$changeText(this.GE_USER.userNameMtext)
      param.userKey = this.GE_USER.userKey
      param.showProfileYn = true
      param.teamName = this.$changeText(this.CHANNEL_DETAIL.nameMtext)
      if (this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn || this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn === 1) {
        param.showProfileYn = false
        params = { follower: param }
      } else {
        params = { follower: param, doType: 'ME' }
      }

      this.axiosQueue.push('saveMemberButton')
      var result = await this.$commonAxiosFunction({
        url: 'https://www.hybric.net:9443/service/tp.saveFollower',
        param: params
      })
      var queueIndex = this.axiosQueue.findIndex((item) => item === 'saveMemberButton')
      // this.axiosQueue = this.axiosQueue.splice(queueIndex, 1)
      this.axiosQueue.splice(queueIndex, 1)
      if (result.data.result === true) {
        if (this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn || this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn === 1) {
          this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn = false
        } else {
          this.CHANNEL_DETAIL.D_CHAN_AUTH.showProfileYn = true
        }
      }
      this.mOpenWelcomePopShowYn = false
      if (this.axiosQueue.findIndex((item) => item === 'addChanList') !== -1) return
      this.axiosQueue.push('addChanList')
      await this.$addChanList(this.chanDetail.targetKey)
      queueIndex = this.axiosQueue.findIndex((item) => item === 'addChanList')
      // this.axiosQueue = this.axiosQueue.splice(queueIndex, 1)
      this.axiosQueue.splice(queueIndex, 1)
    } */
  },
  computed: {
    GE_LOCALE () {
      return this.$i18n.locale
    },
    CHANNEL_DETAIL () {
      if (!this.chanDetail && !this.mDirectTeamKey) return {}
      let teamKey
      if (this.chanDetail) {
        teamKey = this.chanDetail.targetKey || this.chanDetail.teamKey
      } else if (this.mDirectTeamKey) {
        teamKey = this.mDirectTeamKey.teamKey
      }
      var detail = this.$getDetail('TEAM', teamKey)
      console.log('detail')
      console.log(detail)
      if (detail && detail.length > 0) {
        if (detail[0].blackYn) this.$emit('bgcolor', detail[0].blackYn)

        if (detail[0] && (detail[0].blackYn !== undefined && detail[0].blackYn !== null && detail[0].blackYn !== '')) {
          this.$emit('bgcolor', detail[0].blackYn)
        } else {
          this.$emit('bgcolor', false)
        }

        // eslint-disable-next-line no-debugger
        debugger
        if (!detail[0].D_CHAN_AUTH || detail[0].D_CHAN_AUTH === true || (detail[0].D_CHAN_AUTH.followYn && !detail[0].D_CHAN_AUTH.settingYn)) {
          return this.CHANNEL_DETAIL
        } else {
          return detail[0]
        }
      } else {
        if (this.CHANNEL_DETAIL) {
          if (this.CHANNEL_DETAIL && (this.CHANNEL_DETAIL.blackYn !== undefined && this.CHANNEL_DETAIL.blackYn !== null && this.CHANNEL_DETAIL.blackYn !== '')) {
            this.$emit('bgcolor', this.CHANNEL_DETAIL.blackYn)
          } else {
            this.$emit('bgcolor', false)
          }
          return this.CHANNEL_DETAIL
        } else {
          return null
        }
      }
    },
    GE_RECENT_CHANGE_TEAM () {
      return this.$store.getters['D_CHANNEL/GE_RECENT_CHANGE_TEAM']
    },
    settingBackground () {
      var imgPath = 'url(' + (this.CHANNEL_DETAIL.bgDomainPath ? this.CHANNEL_DETAIL.bgDomainPath + this.CHANNEL_DETAIL.bgPathMtext : this.CHANNEL_DETAIL.bgPathMtext) + ')'
      return {
        '--wWidth': this.$getMobileYn() === true ? this.mwWidth + 'px ' : '100% ',
        '--wHeight': this.$getMobileYn() === true ? this.mwHeight + 'px ' : '100% ',
        // '--wWidth': this.$getMobileYn() === true ? window.innerWidth + 'px ' : '100% ',
        // '--wHeight': this.$getMobileYn() === true ? window.innerHeight + 'px ' : '100% ',
        '--backImg': imgPath,
        '--paddingTop': this.$STATUS_HEIGHT + 'px'
      }
    },
    // getWindowHeight () {
    //   return {

    //   }
    // },
    REQ_MEM_OBJ () {
      if (this.CHANNEL_DETAIL && this.CHANNEL_DETAIL.userTeamInfo && this.CHANNEL_DETAIL.userTeamInfo.reqMemberStatus) {
        return { reqMemberStatus: this.CHANNEL_DETAIL.userTeamInfo.reqMemberStatus, reqMemberStr: this.CHANNEL_DETAIL.userTeamInfo.reqMemberStr }
      } else {
        return { reqMemberStatus: '00', reqMemberStr: null }
      }
    },
    GE_CHANNEL_NOTI_QUEUE () {
      return this.$store.getters['D_CHANNEL/GE_CHANNEL_NOTI_QUEUE']
    },
    historyStack () {
      return this.$store.getters['D_HISTORY/hRPage']
    },
    pageUpdate () {
      return this.$store.getters['D_HISTORY/hUpdate']
    },
    GE_USER () {
      return this.$store.getters['D_USER/GE_USER']
    }
  },
  watch: {
    GE_LOCALE: {
      immediate: true,
      handler (value) {
        this.mActiveTabList = [{ display: this.$t('COMMON_TAB_FOLLOWING'), name: 'user' }, { display: this.$t('COMMON_TAB_ALL'), name: 'all' }, { display: this.$t('COMMON_TAB_MANAGING'), name: 'mychannel' }]
      },
      deep: true
    },
    CHANNEL_DETAIL: {
      immediate: true,
      handler (value, old) {
        if (value && value.D_CHAN_AUTH && value.D_CHAN_AUTH.followYn) {
          this.$emit('followYn')
        } else if (value && value.D_CHAN_AUTH && !value.D_CHAN_AUTH.followYn) {
          this.mUnknownYn = true
        }
        var blackYn = false
        if (value && (value.blackYn !== undefined && value.blackYn !== null && value.blackYn !== '')) {
          if (value.blackYn === 1) {
            blackYn = true
          }
        }
        this.$emit('bgcolor', blackYn)
      },
      deep: true
    },
    GE_RECENT_CHANGE_TEAM (value, old) {
      // if (value === this.CHANNEL_DETAIL.teamKey) {
      // console.log('team [' + value + ']의 관련 정보가 변경되었음')
      // }
    },
    GE_MAIN_CHAN_LIST (value, old) {
    },
    pageUpdate (value, old) {
      this.backClick()
      /* if (this.pPopId === hStack[hStack.length - 1]) {
            this.closeSubPop()
        } */
    },
    historyStack (value, old) {
    }
  }
}
</script>

<style scoped>
.favChanBox {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background: #f1f1f1;
  border-radius: 30px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shareChanBox {
  width: 35px;
  height: 35px;
  background: #f1f1f1;
  border-radius: 30px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gBtnSmall{
  float: left!important; margin-top: 0.7rem
}
.gBtnSmall{
  float: left!important; margin-top: 0.7rem
}

.chanDetailWrap{
  /* min-height: 900px;
  max-height: 1000px; */
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1000px;
  /* max-height: 1000px; */
  /* height: 100%vh */

  width: var(--wWidth);
  height: var(--wHeight);
  background-image: var(--backImg);
  padding-top: var(--paddingTop);
}
.officialTitle{
  padding-right: 30px;
}

.chanDetailWrap tr, .chanDetailWrap td{
  padding-top: 1rem;
  min-height: 3rem;
  text-align: left;
}
/* .chanWhiteBox{ display: flex; flex-direction: column;align-items: center; position: relative; width: 100%; height: calc(100% - 50px); } */
.chanWhiteBox{ position: relative; width: 100%; align-items: center; align-content: center; }
.channelItemBoxHeight{height: calc(100% - 50px)!important; position: relative; float: left; width: 100%; padding-top: 140px; overflow: hidden scroll; }
/* .channelItemBox{background-color: #fff; min-height: calc(100% - 250px); position: relative; width: 100%;float: left; box-sizing: border-box;} */
.channelItemBox{background-color: #fff; border-radius: 5px; min-height: calc(100% - 50px); position: relative; width: 100%;float: left; box-sizing: border-box;}
.chanDetailWrap table{width: 85vw; max-width: 400px; }
.chanDetailWrap table img{width: 1.3rem}
.iconTd{display: flex; align-items: flex-start; padding-top: 1.2rem!important;}

.summaryWrap{height: 260px; width: 100%; float: left; position: absolute;}
.summaryWrap2 {height: 50px;  width: 100%; float: left;}

.displayNIm{display: none!important;}
.displayBIm{display: flex!important;}
.chanImgRound{ width: 70px; height: 70px;
  display: flex; align-items: center; justify-content: center; position: relative;
  border-radius: 110px;
  border-radius: 100%;
  flex-shrink: 0; flex-grow: 0;  }
#chanInfoSummary2{width: 100%; padding-top: 0; height: 100%;
/* display: none;  */
flex-direction: column; float: left}
.blockBox{width: 100%; height: 320px;float: left; height: var(--height); min-height: 50px;}
/* .plusMarginBtn{margin-top: 0.6rem; line-height: 1.5;} */

/* btnPlus common.css로 옮김 */

  span {color: white;}
</style>
