export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "FORM_MSG_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트를 기다려주세요!"])}
      },
      "en": {
        "FORM_MSG_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait for the update!"])}
      }
    }
  })
}
