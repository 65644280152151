export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "STICK_NAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 정보"])},
        "STICK_NAME_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 추가"])},
        "STICK_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨이름"])},
        "STICK_TITLE_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기"])},
        "STICK_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 이름을 입력해주세요"])},
        "STICK_MSG_DELETE_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 삭제에 실패했습니다."])}
      },
      "en": {
        "STICK_NAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Info"])},
        "STICK_NAME_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Label"])},
        "STICK_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Name"])},
        "STICK_TITLE_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "STICK_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a label name"])},
        "STICK_MSG_DELETE_FAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete label."])}
      }
    }
  })
}
