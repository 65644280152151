export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "FORM_TITLE_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폰트"])},
        "FORM_BTN_BIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["큰사이즈"])},
        "FORM_BTN_MID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중간사이즈"])},
        "FORM_BTN_SMALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작은사이즈"])}
      },
      "en": {
        "FORM_TITLE_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
        "FORM_BTN_BIG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big Size"])},
        "FORM_BTN_MID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Size"])},
        "FORM_BTN_SMALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Size"])}
      }
    }
  })
}
