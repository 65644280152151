export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "FORM_BTN_ATTACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부"])},
        "FORM_MSG_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일은 최대 10MB까지 첨부할 수 있습니다."])},
        "FORM_BTN_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 선택"])},
        "FORM_MSG_SELECT_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일을 선택해 주세요."])}
      },
      "en": {
        "FORM_BTN_ATTACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach"])},
        "FORM_MSG_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files can be attached up to 10MB."])},
        "FORM_BTN_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Files"])},
        "FORM_MSG_SELECT_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a file."])}
      }
    }
  })
}
