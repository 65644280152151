export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SELECT_GROUP_DROP_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 선택"])},
        "SELECT_MEMBER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버유형"])},
        "SELECT_MANA_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원 관리"])},
        "SELECT_TARGET_SELECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상 선택"])}
      },
      "en": {
        "SELECT_GROUP_DROP_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Selection"])},
        "SELECT_MEMBER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Type"])},
        "SELECT_MANA_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Management"])},
        "SELECT_TARGET_SELECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Selection"])}
      }
    }
  })
}
