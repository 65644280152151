export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MAIN_MSG_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스가 업데이트되어 새로고침됩니다\n잠시후 다시 시도해주세요."])}
      },
      "en": {
        "MAIN_MSG_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service will be updated and refreshed\nPlease try again in a moment."])}
      }
    }
  })
}
