<template>
  <div class="bookPopText">
    <p class="commonBlack textLeft font16" style="height: 100%; ">
      그룹의 <b>성격과 유형</b>에 따라<br> 자유롭게 <b>주소록</b>으로 분류하여 한눈에 정리할 수 있어요! <br><br>
      관리자는 주소록을 대상으로<br>
      1) <b>한번에 알림</b>을 보낼 수 있고,<br>
      2) <b>게시판을 공유</b>하여 그룹의 커뮤니티를 만들어줄 수 있어요!<br><br>
      <b>+ 버튼</b>을 클릭해 주소록을 만들어보세요! <br>
      그리고 주소록에 <b>구성원들을 추가</b>해보세요.<br><br>
      참, <b>매니저도 주소록 관리를 할 수 있어요</b>! 주소록은 매니저가 아닌 <b>구독자와 멤버</b>에게는 보이지 않습니다.<br><br>
      <!-- 하이브릭의 채널 주소록 관리를 활용하여 손쉽게 그룹들을 분류하고 관리해보세요!<br><br> -->
    </p>
    <!-- <img src="../../../assets/images/main/message_logo.png" class="bookPopAlimImg" /> -->
  </div>

</template>
<script>

</script>
<style>
.bookPopText {z-index: 999; top: 20%; background-color: white; position: absolute; overflow: hidden scroll; margin: 10px; border-radius: 10px; padding: 15px; min-height: 300px; max-height: 70%;}
.bookPopAlimImg {width:100px;right: 20px;position: absolute;float:right;}
</style>
