export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "FORM_BTN_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성"])},
        "FORM_BTN_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청"])},
        "FORM_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송하기"])},
        "FORM_BTN_LONG_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청하기"])},
        "FORM_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정하기"])},
        "FORM_BTN_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시하기"])},
        "FORM_TITLE_RECEIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신"])},
        "FORM_MSG_TARGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쪽지를 보낼 대상을 선택해주세요."])},
        "FORM_TITLE_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
        "FORM_BTN_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명"])},
        "FORM_BTN_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
        "FORM_BTN_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
        "FORM_TITLE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판"])},
        "FORM_MSG_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을 입력해주세요"])},
        "FORM_BTN_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일"])},
        "FORM_MSG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명인증을 하면 익명게시판에 글을 작성할 수 있어요"])},
        "FORM_MSG_SEND_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쪽지를 발송하시겠습니까?"])},
        "FORM_MSG_APPLY_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림을 신청하시겠습니까?"])},
        "FORM_MSG_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글을 수정하시겠습니까?"])},
        "FORM_MSG_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글을 저장하시겠습니까?"])},
        "FORM_MSG_SUCCESS_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청되었습니다."])},
        "FORM_MSG_SUCCESS_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 되었습니다."])},
        "FORM_MSG_NOPERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한없음"])},
        "FORM_MSG_REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림을 신청하는 사유를 입력해주세요"])},
        "FORM_MSG_SU_TARGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신자를 선택해주세요"])},
        "FORM_MSG_NOTI_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쪽지 내용을 입력해주세요"])},
        "FORM_MSG_POST_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글의 내용을 입력해주세요"])},
        "FORM_MSG_DIFFBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 게시판에 작성 권한이 없습니다. 다른 게시판을 선택해주세요."])},
        "FORM_MSG_NOBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글을 작성할 게시판을 선택해주세요."])},
        "FORM_MSG_SUCCESS_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시되었습니다."])},
        "FORM_MSG_SUCCESS_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정되었습니다."])},
        "FORM_MSG_FAIL_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시되지 못했습니다."])},
        "FORM_MSG_SUCCESS_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송되었습니다."])},
        "FORM_MSG_FAIL_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송하지 못했습니다."])},
        "FORM_MSG_DRAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이곳에 파일을 올려 주세요."])},
        "COMM_TITLE_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목은 256자 이내로 작설해주세요."])}
      },
      "en": {
        "FORM_BTN_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write"])},
        "FORM_BTN_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "FORM_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "FORM_BTN_LONG_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "FORM_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "FORM_BTN_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "FORM_TITLE_RECEIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive"])},
        "FORM_MSG_TARGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the destination to send the noti to."])},
        "FORM_TITLE_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "FORM_BTN_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
        "FORM_BTN_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "FORM_BTN_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "FORM_TITLE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board"])},
        "FORM_MSG_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the title"])},
        "FORM_BTN_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
        "FORM_MSG_SEND_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send noti?"])},
        "FORM_MSG_APPLY_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to apply for noti?"])},
        "FORM_MSG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you authenticate your real name, you can write on the anonymous board."])},
        "FORM_MSG_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to edit the post?"])},
        "FORM_MSG_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save the post?"])},
        "FORM_MSG_SUCCESS_APPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied."])},
        "FORM_MSG_SUCCESS_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved."])},
        "FORM_MSG_NOPERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permissions"])},
        "FORM_MSG_REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a reason for requesting notification"])},
        "FORM_MSG_SU_TARGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a recipient"])},
        "FORM_MSG_NOTI_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the noti content."])},
        "FORM_MSG_POST_NOCONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the contents of the post"])},
        "FORM_MSG_DIFFBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to create on this board. Please select a different board."])},
        "FORM_MSG_NOBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the board on which you want to create a post."])},
        "FORM_MSG_SUCCESS_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted."])},
        "FORM_MSG_SUCCESS_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited."])},
        "FORM_MSG_FAIL_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to publish."])},
        "FORM_MSG_SUCCESS_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent."])},
        "FORM_MSG_FAIL_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send."])},
        "FORM_MSG_DRAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop the file here."])},
        "COMM_TITLE_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write the title within 256 characters."])}
      }
    }
  })
}
