export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MEM_DETAIL_TITLE_ADDINFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부가정보"])},
        "MEM_DETAIL_MGS_NOSET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정된 값이 없습니다. 지금 바로 추가해보세요!"])},
        "MEM_DETAIL_NAME_NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 정보"])}
      },
      "en": {
        "MEM_DETAIL_TITLE_ADDINFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
        "MEM_DETAIL_MGS_NOSET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No value set. Add it now!"])},
        "MEM_DETAIL_NAME_NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New info"])}
      }
    }
  })
}
