export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOARD_MSG_NOBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판이 없어요."])},
        "EDIT_BOARD_MSG_CREBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼을 눌러 게시판을 생성해보세요."])},
        "EDIT_BOARD_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판을 삭제하시겠습니까?"])}
      },
      "en": {
        "EDIT_BOARD_MSG_NOBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's no board"])},
        "EDIT_BOARD_MSG_CREBOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the button to create a board."])},
        "EDIT_BOARD_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the board?"])}
      }
    }
  })
}
