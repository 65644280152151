<template>
<div class="w-100P" style="margin-top:50px;">
아직 구현 X
</div>
</template>

<script>
export default {
  props: {
    propData: {}
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style >

</style>
