export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "COMM_MAN_STICKER_POP_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 관리"])},
        "COMMON_MY_TAG_TILTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 태그"])},
        "COMM_MSG_DELETE_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그를 삭제하시겠습니까?"])},
        "COMM_MSG_REQ_SELECT_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그를 선택해주세요"])},
        "COMM_CHOSE_COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["색상"])},
        "COMM_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기"])}
      },
      "en": {
        "COMM_MAN_STICKER_POP_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Management"])},
        "COMMON_MY_TAG_TILTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tag"])},
        "COMM_MSG_DELETE_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete a tag?"])},
        "COMM_MSG_REQ_SELECT_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a tag"])},
        "COMM_CHOSE_COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
        "COMM_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])}
      }
    }
  })
}
