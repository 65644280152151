export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "CHAN_MENU_TITLE_MENU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 메뉴"])},
        "CHAN_MENU_QUICK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편리기능"])},
        "CHAN_MENU_SEND_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림작성"])},
        "CHAN_MENU_WRITE_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시글 작성"])},
        "MANA_NAME_CAHN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 관리"])},
        "MANA_NAME_ADDRBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 관리"])},
        "MANA_NAME_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판 관리"])},
        "CHAN_MSG_NOFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 채널의 멤버가 아닙니다. 멤버로 신청 후 이용해주세요."])}
      },
      "en": {
        "CHAN_MENU_TITLE_MENU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Menu"])},
        "CHAN_MENU_QUICK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Menu"])},
        "CHAN_MENU_SEND_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Noti"])},
        "CHAN_MENU_WRITE_POST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a Post"])},
        "MANA_NAME_CAHN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Channel"])},
        "MANA_NAME_ADDRBOOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Address Book"])},
        "MANA_NAME_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Board"])},
        "CHAN_MSG_NOFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not a follower of that channel. Please apply for followers."])}
      }
    }
  })
}
