export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "REPO_TITLE_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고하는 사유를 선택해주세요."])},
        "REPO_MSG_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원님의 신고는 익명으로 처리 됩니다. 응급한 상황에 있다고 생각된다면 응급서비스 기관에 연락하시길 바랍니다."])},
        "REPO_TITLE_REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고하는 사유:"])},
        "REPO_MSG_SPAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스팸"])},
        "REPO_MSG_NUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나체 이미지 또는 성적 행위"])},
        "REPO_MSG_NOLIKE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마음에 들지 않습니다."])},
        "REPO_MSG_FALSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사기 또는 거짓"])},
        "REPO_MSG_HATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["혐오 발언 또는 상징"])},
        "REPO_MSG_BULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["따돌림 또는 괴롭힘"])},
        "REPO_MSG_VIOLENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폭력"])},
        "REPO_MSG_OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 문제"])},
        "REPO_BTN_SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출하기"])}
      },
      "en": {
        "REPO_TITLE_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the reason for reporting."])},
        "REPO_MSG_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your report will be anonymous. If you think you are in an emergency situation, please contact the emergency service."])},
        "REPO_TITLE_REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for reporting:"])},
        "REPO_MSG_SPAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spam"])},
        "REPO_MSG_NUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nude image or sexual behavior"])},
        "REPO_MSG_NOLIKE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't like it."])},
        "REPO_MSG_FALSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud or False"])},
        "REPO_MSG_HATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hate speech or symbol"])},
        "REPO_MSG_BULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bullying or harassment"])},
        "REPO_MSG_VIOLENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violence"])},
        "REPO_MSG_OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other issues"])},
        "REPO_BTN_SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
      }
    }
  })
}
