export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EDIT_BOOK_TITLE_PEOPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인원"])},
        "EDIT_BOOK_BTN_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ 선택하기"])},
        "EDIT_BOOK_NAME_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나"])},
        "EDIT_BOOK_MSG_SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 선택되었습니다."])},
        "EDIT_BOOK_MSG_ONEPEOPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자는 한 명만 부여 가능합니다!"])}
      },
      "en": {
        "EDIT_BOOK_TITLE_PEOPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
        "EDIT_BOOK_BTN_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add"])},
        "EDIT_BOOK_NAME_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
        "EDIT_BOOK_MSG_SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already selected"])},
        "EDIT_BOOK_MSG_ONEPEOPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one person in charge can be assigned!"])}
      }
    }
  })
}
