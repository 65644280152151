export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "STICK_NAME_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 설정"])},
        "STICK_TITLE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨 목록"])},
        "STICK_MSG_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨을 클릭해 이름/색깔을 수정하거나 삭제하세요!"])},
        "STICK_MSG_NOLABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택가능한 분류가 없습니다. <br>분류를 추가해주세요!"])}
      },
      "en": {
        "STICK_NAME_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Labels"])},
        "STICK_TITLE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label List"])},
        "STICK_MSG_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the label to edit or delete the name/color!"])},
        "STICK_MSG_NOLABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no label available to select. <br>Please add a label!"])}
      }
    }
  })
}
