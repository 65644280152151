export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MENU_BTN_INTRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하이브릭 소개"])},
        "MENU_BTN_FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 찾는 질문"])},
        "MENU_BTN_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])},
        "MENU_BTN_REPO_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류접수"])},
        "LANG_INTO_KOREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
        "LANG_INTO_ENG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])}
      },
      "en": {
        "MENU_BTN_INTRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing Hybric"])},
        "MENU_BTN_FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "MENU_BTN_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "MENU_BTN_REPO_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Errors"])},
        "LANG_INTO_KOREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
        "LANG_INTO_ENG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
      }
    }
  })
}
