export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MEM_DETAIL_NAME_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주관식"])},
        "MEM_DETAIL_NAME_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객관식"])},
        "MEM_DETAIL_BNT_NUMONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자만 허용"])},
        "MEM_DETAIL_BTN_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글자수 최대"])},
        "MEM_DETAIL_NAME_DROP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드롭다운"])},
        "MEM_DETAIL_NAME_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트"])},
        "MEM_DETAIL_TITLE_INFONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보명"])},
        "MEM_DETAIL_NAME_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버정보 수정"])},
        "MEM_DETAIL_MSG_INFONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버 정보 명을 입력해주세요."])},
        "MEM_DETAIL_MSG_QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문을 입력해주세요."])},
        "MEM_DETAIL_MSG_QTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문의 유형을 선택해주세요."])},
        "MEM_DETAIL_TITLE_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택항"])}
      },
      "en": {
        "MEM_DETAIL_TITLE_ADDINFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
        "MEM_DETAIL_NAME_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short<br>Answer"])},
        "MEM_DETAIL_NAME_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple<br>choice"])},
        "MEM_DETAIL_BNT_NUMONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Only"])},
        "MEM_DETAIL_BTN_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "MEM_DETAIL_NAME_DROP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Down"])},
        "MEM_DETAIL_NAME_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
        "MEM_DETAIL_TITLE_INFONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Name"])},
        "MEM_DETAIL_NAME_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Member Info"])},
        "MEM_DETAIL_MSG_INFONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the member information name."])},
        "MEM_DETAIL_MSG_QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a question."])},
        "MEM_DETAIL_MSG_QTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the type of question."])},
        "MEM_DETAIL_TITLE_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
      }
    }
  })
}
