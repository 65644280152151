export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "INFO_TITLE_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경할 이메일 주소 인증"])},
        "INFO_MSG_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소 입력"])},
        "INFO_MSG_VERINUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 입력"])},
        "INFO_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 발송"])},
        "INFO_BTN_NOWEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 등록된 이메일"])},
        "INFO_MSG_SAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 이메일과 변경 이메일이 동일합니다."])},
        "INFO_MSG_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 형식이 올바르지 않습니다."])},
        "INFO_MSG_AFTER_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호가 발송되었습니다."])},
        "INFO_MSG_NOEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 등록되어 있는 이메일이 없습니다."])},
        "INFO_MSG_NONUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호를 입력해주세요."])},
        "INFO_BTN_VERI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증하기"])}
      },
      "en": {
        "INFO_TITLE_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Verification to change"])},
        "INFO_MSG_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
        "INFO_MSG_VERINUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter verification number"])},
        "INFO_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "INFO_BTN_NOWEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email currently registered"])},
        "INFO_MSG_SAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current email and the email you want to change are the same."])},
        "INFO_MSG_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email format is not valid."])},
        "INFO_MSG_AFTER_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification number has been sent."])},
        "INFO_MSG_NOEMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No emails are currently registered."])},
        "INFO_MSG_NONUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification number."])},
        "INFO_BTN_VERI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])}
      }
    }
  })
}
