export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "CRE_BTN_EDITBG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경편집"])},
        "CRE_BTN_DELETE_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널삭제"])},
        "CRE_MSG_CHANNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널명을 20자 이내로 입력해주세요"])},
        "CRE_TITLE_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소개글"])},
        "CRE_MSG_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널에 대한 설명을 40글자 이내로 입력해주세요."])},
        "CRE_TITLE_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 상단 글자색"])},
        "CRE_BTN_HEAD_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본"])},
        "CRE_BTN_HEAD_WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["흰색"])},
        "CRE_BTN_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기"])},
        "CRE_BTN_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 생성"])},
        "EDIT_NAME_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 수정"])},
        "CRE_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널명을 입력해주세요."])},
        "CRE_MSG_NODESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 소개를 입력해주세요."])},
        "CRE_MSG_NOCATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널의 산업군을 선택해주세요."])},
        "CRE_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널을 삭제하시겠습니까?"])}
      },
      "en": {
        "CRE_BTN_EDITBG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Background"])},
        "CRE_BTN_DELETE_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the Channel"])},
        "CRE_MSG_CHANNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter up to 20 characters in the channel name"])},
        "CRE_TITLE_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Description"])},
        "CRE_MSG_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter up to 40 characters in the channel description"])},
        "CRE_TITLE_HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color of the Channel Header"])},
        "CRE_BTN_HEAD_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
        "CRE_BTN_HEAD_WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
        "CRE_BTN_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "CRE_BTN_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "EDIT_NAME_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "CRE_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the channel name."])},
        "CRE_MSG_NODESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the channel description."])},
        "CRE_MSG_NOCATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the channel category."])},
        "CRE_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the channel?"])}
      }
    }
  })
}
