export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "TODO_COMPLETE_CONFIRM_MSG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료하시겠습니까?"])},
        "TODO_COMPLETE_NO_COMMENT_CONFIRM_MSG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료메세지를 작성하지 않았습니다. 그래도 완료하시겠습니까?"])}
      },
      "en": {
        "TODO_COMPLETE_CONFIRM_MSG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to complete the Todo with a message?"])},
        "TODO_COMPLETE_CONFIRM_MSG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't written a completion message. Would you still like to proceed with the completion?"])}
      }
    }
  })
}
