export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "CHAN_TITLE_CREDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개설일"])},
        "CHAN_TITLE_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자"])},
        "CHAN_TITLE_CONTNUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적 쪽지"])},
        "CHAN_BTN_UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독취소"])},
        "CHAN_BTN_MEMREQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버신청"])},
        "CHAN_BTN_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독하기"])},
        "CHAN_NAME_GUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손님"])},
        "CHAN_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
        "CHAN_MSG_AGAIN_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원님은 현재 구독자상태이며,<br>언제든지 다시 멤버신청을 할 수 있습니다!"])},
        "EDIT_NAME_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 수정"])},
        "CHAN_MSG_NOCANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자는 구독취소가 불가능합니다.<br>소유자에게 문의해주세요."])},
        "CHAN_MSG_UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 취소가 완료되었습니다."])},
        "CHAN_MSG_ASK_UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독을 취소하시겠습니까?"])},
        "CHAN_MSG_OFF_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 알림이 비활성화 되었습니다."])},
        "CHAN_MSG_ON_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 알림이 활성화 되었습니다."])}
      },
      "en": {
        "CHAN_TITLE_CREDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "CHAN_TITLE_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers"])},
        "CHAN_TITLE_CONTNUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Contents"])},
        "CHAN_BTN_UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
        "CHAN_BTN_MEMREQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Request"])},
        "CHAN_BTN_FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
        "CHAN_NAME_GUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
        "CHAN_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "CHAN_MSG_AGAIN_MEMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently a follower,<br>and you can apply again at any time!"])},
        "EDIT_NAME_CHAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Channel"])},
        "CHAN_MSG_NOCANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager cannot unfollow<br>Contact the owner."])},
        "CHAN_MSG_UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This channel is unfollowed."])},
        "CHAN_MSG_ASK_UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to unfollow this channel?"])},
        "CHAN_MSG_OFF_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel notification  is disabled."])},
        "CHAN_MSG_ON_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel notification is enabled."])}
      }
    }
  })
}
