export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "PROF_TITLE_DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입일"])},
        "PROF_BTN_POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 처리방침"])},
        "PROF_BTN_TERMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관"])},
        "PROF_BTN_CASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캐시정보 삭제"])},
        "PROF_TITLE_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버전정보"])},
        "PROF_BTN_LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하이브릭 로그아웃"])},
        "PROF_BTN_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하이브릭을 탈퇴하려면 여기를 눌러주세요"])},
        "PROF_BTN_EDIT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 변경"])},
        "PROF_TITLE_LATE_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신버전"])}
      },
      "en": {
        "PROF_TITLE_DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded"])},
        "PROF_BTN_POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "PROF_BTN_TERMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
        "PROF_BTN_CASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete cash"])},
        "PROF_TITLE_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "PROF_BTN_LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout Hybric"])},
        "PROF_BTN_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to delete your account from Hybric"])},
        "PROF_BTN_EDIT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Email"])},
        "PROF_TITLE_LATE_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])}
      }
    }
  })
}
