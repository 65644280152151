export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "MEM_DETAIL_NAME_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주관식"])},
        "MEM_DETAIL_NAME_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객관식"])},
        "MEM_DETAIL_BNT_NUMONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자만"])},
        "MEM_DETAIL_NAME_DROP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드롭다운"])},
        "MEM_DETAIL_NAME_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트"])}
      },
      "en": {
        "MEM_DETAIL_TITLE_ADDINFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
        "MEM_DETAIL_NAME_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Answer"])},
        "MEM_DETAIL_NAME_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple choice"])},
        "MEM_DETAIL_BNT_NUMONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Only"])},
        "MEM_DETAIL_NAME_DROP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Down"])},
        "MEM_DETAIL_NAME_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])}
      }
    }
  })
}
