export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "RECEPT_BTN_REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거절"])},
        "RECEPT_BTN_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인"])},
        "RECEPT_BTN_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체선택"])},
        "RECEPT_MSG_NOAPPLI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 가능한 요청 목록이 없습니다."])},
        "RECEPT_MSG_NOSELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 목록이 없습니다."])},
        "RECEPT_MSG_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버신청을 승인하시겠습니까?"])},
        "RECEPT_MSG_REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버신청을 거절하시겠습니까?"])},
        "RECEPT_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 보내기"])}
      },
      "en": {
        "RECEPT_BTN_REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
        "RECEPT_BTN_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "RECEPT_BTN_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
        "RECEPT_MSG_NOAPPLI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no list of applicable requests."])},
        "RECEPT_MSG_NOSELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No list selected."])},
        "RECEPT_MSG_ACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to accept the membership application?"])},
        "RECEPT_MSG_REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reject the membership application?"])},
        "RECEPT_BTN_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Noti"])}
      }
    }
  })
}
