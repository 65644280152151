export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "BCARD_NAME_COMMENTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 지원O"])},
        "BCARD_NAME_FILEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 업로드O"])},
        "BCARD_NAME_ANONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["익명"])},
        "BCARD_NAME_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실명"])}
      },
      "en": {
        "BCARD_NAME_COMMENTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments O"])},
        "BCARD_NAME_FILEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files O"])},
        "BCARD_NAME_ANONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
        "BCARD_NAME_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous"])}
      }
    }
  })
}
