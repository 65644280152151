export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "EMPT_MSG_NONE_ADDR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비어있는 주소록"])},
        "EMPT_MSG_NONE_MEMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비어있는 멤버"])},
        "NONE_MSG_REGIST_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 이메일이 없습니다."])},
        "NONE_MSG_REGIST_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 번호가 없습니다."])},
        "EDIT_BOOK_TITLE_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소록 수정"])}
      },
      "en": {
        "EMPT_MSG_NONE_ADDR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty team"])},
        "EMPT_MSG_NONE_MEMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty member list"])},
        "NONE_MSG_REGIST_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No registered email found."])},
        "NONE_MSG_REGIST_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No registered phone number found."])},
        "EDIT_BOOK_TITLE_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address Books"])}
      }
    }
  })
}
