export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SAMP_NAME_ADD_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 추가"])},
        "SAMP_NAME_EDIT_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 수정"])},
        "SAMP_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플명"])},
        "SAMP_TITLE_ICON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이콘"])},
        "SAMP_TITLE_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플내용"])},
        "SAMP_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플명을 입력해주세요."])},
        "SAMP_MSG_NOICON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 아이콘을 선택해주세요."])},
        "SAMP_MSG_EDITED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플이 수정되었습니다."])},
        "SAMP_MSG_ADDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플이 추가되었습니다."])}
      },
      "en": {
        "SAMP_NAME_ADD_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Sample"])},
        "SAMP_NAME_EDIT_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Sample"])},
        "SAMP_TITLE_ICON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
        "SAMP_TITLE_CONTENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Contents"])},
        "SAMP_MSG_NONAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a sample name."])},
        "SAMP_MSG_NOICON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a sample icon."])},
        "SAMP_MSG_EDITED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sample has been edited."])},
        "SAMP_MSG_ADDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sample have been added."])},
        "SAMP_TITLE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Name"])}
      }
    }
  })
}
