export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "STICK_MSG_ADD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라벨을 추가해주세요!"])}
      },
      "en": {
        "STICK_MSG_ADD_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add a label!"])}
      }
    }
  })
}
