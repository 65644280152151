export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "CRE_MSG_AFTER_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널이 삭제되었습니다."])},
        "CRE_MSG_AFTER_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널이 생성되었습니다."])},
        "CRE_MSG_AFTER_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널정보가 수정되었습니다."])},
        "MENU_BTN_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])}
      },
      "en": {
        "CRE_MSG_AFTER_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel was deleted."])},
        "CRE_MSG_AFTER_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel was created."])},
        "CRE_MSG_AFTER_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel was Edited."])},
        "MENU_BTN_REQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])}
      }
    }
  })
}
