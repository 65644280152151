export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "SAMP_NAME_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 선택"])},
        "SAMP_TITLE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 목록"])},
        "SAMP_MSG_NOSMAPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택가능한 샘플이 없습니다."])},
        "SAMP_TITLE_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기"])},
        "SAMP_BTN_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
        "SAMP_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
        "SAMP_MSG_BEFORE_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플을 삭제하시겠습니까?"])},
        "SAMP_MSG_AFTER_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플이 삭제되었습니다."])}
      },
      "en": {
        "SAMP_NAME_SAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select sample"])},
        "SAMP_TITLE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample List"])},
        "SAMP_MSG_NOSMAPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no selectable sample."])},
        "SAMP_TITLE_PREVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "SAMP_BTN_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "SAMP_BTN_EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "SAMP_MSG_BEFORE_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the sample?"])},
        "SAMP_MSG_AFTER_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sample has been deleted.The sample has been deleted."])}
      }
    }
  })
}
