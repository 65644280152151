export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "DETAIL_MSG_FEATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 모드에서는 지원하지 않는 기능입니다."])},
        "DETAIL_MSG_SAVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되었습니다!"])}
      },
      "en": {
        "DETAIL_MSG_FEATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not supported in this mode."])},
        "DETAIL_MSG_SVAED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved!"])}
      }
    }
  })
}
