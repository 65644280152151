export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "RECE_TITLE_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 받은 쪽지"])},
        "RECE_MSG_NOTIDESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 이력은 앱 설치 후 받은 쪽지 이력이며, 앱 삭제 혹은 데이터 삭제 시 사라지게 됩니다."])},
        "RECE_BTN_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 삭제"])},
        "RECE_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 이력을 전부 삭제하시겠습니까?"])}
      },
      "en": {
        "RECE_TITLE_NOTI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Notifications"])},
        "RECE_MSG_NOTIDESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Activities shows notifications you received after you install Hybric app, so if you delete an app or data, it disappears."])},
        "RECE_BTN_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete All"])},
        "RECE_MSG_DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete all received history?"])}
      }
    }
  })
}
