export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ko": {
        "INPUT_MSG_INPUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력해주세요."])},
        "MEM_DETAIL_MSG_MEMTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멤버 유형을 입력해주세요."])}
      },
      "en": {
        "INPUT_MSG_INPUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Text."])},
        "MEM_DETAIL_MSG_MEMTYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a member type."])}
      }
    }
  })
}
